import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { sdgdata } from "components/data/SDG";
import { certificationsArray } from "components/data/certifications";
import { frameworksArray } from "components/data/frameworks";
import { targetsArray } from "components/data/targets";
import { ratingsArray } from "components/data/ratings";
import { regulationArray } from "components/data/regulation";
import { AddOutlined, EditOutlined } from "@mui/icons-material";
import { Link } from "react-router-dom";

function Card({ data, color }) {
  return (
    <div className={`relative z-[-1000]`}>
      {data && (
        <img
          src={data.imgSource}
          alt="cover"
          className={`object-cover h-[70px] w-[70px] transition-all`}
        />
      )}

      {color ? (
        <div
          className={`absolute top-0 left-0 w-[70px] h-[70px] text-[#2AE4FF] opacity-0 transition duration-500 hover:opacity-100 cursor-pointer p-4
          ${color === "#e5243b" ? "bg-[#e5243b]" : ""}
          ${color === "#dda63a" ? "bg-[#dda63a]" : ""}
          ${color === "#4C9F38" ? "bg-[#4C9F38]" : ""}
          ${color === "#C5192D" ? "bg-[#C5192D]" : ""}
          ${color === "#FF3A21" ? "bg-[#FF3A21]" : ""}
          ${color === "#26BDE2" ? "bg-[#26BDE2]" : ""}
          ${color === "#FCC30B" ? "bg-[#FCC30B]" : ""}
          ${color === "#A21942" ? "bg-[#A21942]" : ""}
          ${color === "#FD6925" ? "bg-[#FD6925]" : ""}
          ${color === "#DD1367" ? "bg-[#DD1367]" : ""}
          ${color === "#FD9D24" ? "bg-[#FD9D24]" : ""}
          ${color === "#BF8B2E" ? "bg-[#BF8B2E]" : ""}
          ${color === "#3F7E44" ? "bg-[#3F7E44]" : ""}
          ${color === "#0A97D9" ? "bg-[#0A97D9]" : ""}
          ${color === "#56C02B" ? "bg-[#56C02B]" : ""}
          ${color === "#00689D" ? "bg-[#00689D]" : ""}
          ${color === "#19486A" ? "bg-[#19486A]" : ""}
          `}
        >
          <p className="text-left font-bold text-gray-300 text-xl">
            {data.backHeading}
          </p>
          <p className="text-left font-normal text-sm text-white border-b-2 pb-4">
            {data.backText}
          </p>
          <div className="pt-2 text-white">
            <div className="">
              <p className="font-bold text-xl">Target : {data.target}</p>
            </div>
          </div>
        </div>
      ) : (
        ""
      )}
    </div>
  );
}

const LogoCard = ({ data }) => {
  return (
    <div className={`relative z-[-1000] cursor-pointer`}>
      {data && (
        <div className="flex items-center justify-center h-full">
          <img
            src={data.imgSource}
            alt="cover"
            className={`object-cover w-[70px] transition-all`}
          />
        </div>
      )}
      {/* <p className='absolute -bottom-8 left-14 mt-4'>{data.label}</p> */}
    </div>
  );
};

const Preferences = () => {
  const sdgs = useSelector((state) => state.preference.sdgs);
  const frameworks = useSelector((state) => state.preference.frameworks);
  const regulation = useSelector((state) => state.preference.regulation);
  const certifications = useSelector(
    (state) => state.preference.certifications
  );
  const targets = useSelector((state) => state.preference.targets);
  const ratings = useSelector((state) => state.preference.ratings);
  const [filteredSdgs, setFilteredSdgs] = useState([]);
  const [filteredFrameworks, setFilteredFrameworks] = useState([]);
  const [filteredCertifications, setFilteredCertifications] = useState([]);
  const [filteredTargets, setFilteredTargets] = useState([]);
  const [filteredRatings, setFilteredRatings] = useState([]);
  const [filteredRegulation, setFilteredRegulation] = useState([]);
  const [edit, setEdit] = useState(false);

  const filterSdgsById = (sdgs, selectedSdgs) => {
    console.log(selectedSdgs);
    return sdgs.filter((sdg) => selectedSdgs.includes(sdg.id));
  };

  const filterframeworksById = (frameworks, selected) => {
    return frameworks.filter((sdg) => selected.includes(sdg.id));
  };

  const filtercertificationsById = (certifications, selected) => {
    return certifications.filter((sdg) => selected.includes(sdg.id));
  };

  const filtertargetsById = (targets, selected) => {
    return targets.filter((sdg) => selected.includes(sdg.id));
  };

  const filterratingsById = (ratings, selected) => {
    return ratings.filter((sdg) => selected.includes(sdg.id));
  };

  const filterregulationById = (regulation, selected) => {
    return regulation.filter((sdg) => selected.includes(sdg.id));
  };

  const handleEditToggle = () => {
    setEdit((prevEdit) => !prevEdit);
  };

  useEffect(() => {
    setFilteredSdgs(filterSdgsById(sdgdata, sdgs));
    setFilteredFrameworks(filterframeworksById(frameworksArray, frameworks));
    setFilteredCertifications(
      filtercertificationsById(certificationsArray, certifications)
    );
    setFilteredTargets(filtertargetsById(targetsArray, targets));
    setFilteredRatings(filterratingsById(ratingsArray, ratings));
    setFilteredRegulation(filterregulationById(regulationArray, regulation));
  }, [sdgs, edit]);

  console.log({
    filteredSdgs,
    filteredFrameworks,
    frameworks,
    filteredCertifications,
    certifications,
    filteredTargets,
    targets,
    filteredRatings,
    filteredRegulation,
    ratings,
  });
  return (
    <div>
      <div className="flex justify-between items-center mb-6">
        <div className="text-neutral-800 text-[15px] font-bold leading-tight">
          Preferences
        </div>
        <div>
          <div className="text-sky-600 text-[10px] font-bold leading-[13px] space-x-2">
            <button
              onClick={handleEditToggle}
              className="text-sky-600 text-[10px] font-normal leading-[13px] space-x-2 me-2"
            >
              <EditOutlined style={{ fontSize: "18px" }} />
              <span>Edit</span>
            </button>
          </div>
        </div>
      </div>
      <h3 className="text-neutral-800 text-[13px] font-bold leading-none mt-8">
        Frameworks
      </h3>
      <div className="w-full h-[0px] border border-gray-200 mt-2 mb-4"></div>
      <div className="grid grid-cols-8 gap-2">
        {filteredFrameworks.map((data) => {
          return <LogoCard key={data.id} data={data} />;
        })}
        {edit && (
          <Link to="../home/dashboard/preferences?currentPage=2">
            <div className="w-[70px] h-[70px] rounded-lg border border-dashed border-neutral-400 flex justify-center items-center cursor-pointer">
              <AddOutlined />
            </div>
          </Link>
        )}
      </div>
      {/* Test */}

      <h3 className="text-neutral-800 text-[13px] font-bold leading-none mt-8">
        Regulations
      </h3>
      <div className="w-full h-[0px] border border-gray-200 mt-2 mb-4"></div>
      <div className="grid grid-cols-8 gap-2">
        {filteredRegulation.map((data) => {
          return <LogoCard key={data.id} data={data} />;
        })}
        {edit && (
          <Link to="../home/dashboard/preferences?currentPage=6">
            <div className="w-[70px] h-[70px] rounded-lg border border-dashed border-neutral-400 flex justify-center items-center cursor-pointer">
              <AddOutlined />
            </div>
          </Link>
        )}
      </div>
      <div className="flex gap-12 mx-4 mt-2">
        {filteredRegulation.map((regulation) => (
          <p key={regulation.id} className="text-sm">
            {regulation.label}
          </p>
        ))}
      </div>
      {/* Test */}
      <h3 className="text-neutral-800 text-[13px] font-bold leading-none mt-8">
        Targets
      </h3>
      <div className="w-full  border border-gray-200 mt-2 mb-4"></div>
      <div className="grid h-[20%] grid-cols-8 gap-2">
        {filteredTargets.map((data) => {
          return <LogoCard key={data.id} data={data} />;
        })}
        {edit && (
          <Link to="../home/dashboard/preferences?currentPage=4">
            <div className="w-[70px] h-[70px] rounded-lg border border-dashed border-neutral-400 flex justify-center items-center cursor-pointer">
              <AddOutlined />
            </div>
          </Link>
        )}
      </div>
      <div className="flex gap-12 mx-4 mt-2">
        {filteredTargets.map((targets) => (
          <p key={targets.id} className="text-sm">
            {targets.label}
          </p>
        ))}
      </div>
      <h3 className="text-neutral-800 text-[13px] font-bold leading-none mt-8">
        SDG’s
      </h3>
      <div className="w-full h-[0px] border border-gray-200 mt-2 mb-4"></div>
      <div className="grid grid-cols-8 gap-2 ">
        {filteredSdgs.map((sdg) => (
          <Card key={sdg.id} data={sdg} color={sdg.color} />
        ))}
        {edit && (
          <Link to="../home/dashboard/preferences?currentPage=1">
            <div className="w-[70px] h-[70px] rounded-lg border border-dashed border-neutral-400 flex justify-center items-center cursor-pointer">
              <AddOutlined />
            </div>
          </Link>
        )}
      </div>
      <h3 className="text-neutral-800 text-[13px] font-bold leading-none mt-8">
        Certifications
      </h3>
      <div className="w-full h-[0px] border border-gray-200 mt-2 mb-4"></div>
      <div className="grid grid-cols-8 gap-2">
        {filteredCertifications.map((data) => {
          return <LogoCard key={data.id} data={data} />;
        })}
        {edit && (
          <Link to="../home/dashboard/preferences?currentPage=3">
            <div className="w-[70px] h-[70px] rounded-lg border border-dashed border-neutral-400 flex justify-center items-center cursor-pointer ">
              <AddOutlined />
            </div>
          </Link>
        )}
      </div>
      <h3 className="text-neutral-800 text-[13px] font-bold leading-none mt-8">
        Ratings
      </h3>
      <div className="w-full  border border-gray-200 mt-2 mb-4"></div>
      <div className="grid grid-cols-8 gap-2 pb-2">
        {filteredRatings.map((data) => {
          return <LogoCard key={data.id} data={data} />;
        })}
        {edit && (
          <Link to="../home/dashboard/preferences?currentPage=5">
            <div className="w-[70px] h-[70px] rounded-lg border border-dashed border-neutral-400 flex justify-center items-center cursor-pointer">
              <AddOutlined />
            </div>
          </Link>
        )}
      </div>
    </div>
  );
};

export default Preferences;
