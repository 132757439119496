import BCorp from 'assets/certifications/BCorp.svg';
import ISO14001 from 'assets/certifications/ISO14001.gif';
import ungc from 'assets/certifications/UNGC.png';

export const certificationsArray = [
  {
    id: 1,
    imgSource: BCorp,
    label: 'BCORP',
    size: 36,
  },
  {
    id: 2,
    imgSource: ISO14001,
    label: 'ISO 14001',
    size: 56,
  },
  {
    id: 3,
    imgSource: ungc,
    label: 'UNGC',
    size: 36,
  },
];
