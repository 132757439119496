import React, { useState } from "react";
import { useProSidebar } from "react-pro-sidebar";
const ParentalLeave = () => {
  const { collapseSidebar, collapsed } = useProSidebar();
  const [selectedPeriod, setSelectedPeriod] = useState("Jan-March");
  const handlePeriodChange = (period) => {
    setSelectedPeriod(period);
  };
  const renderTableHeaders = () => {
    switch (selectedPeriod) {
      case "Jan-March":
        return (
          <>
    
    <th colSpan="1" className="px-4 py-2 text-start border w-[38%]">
           
            </th>
            <th colSpan="3" className="px-4 py-2 text-center text-[#727272]">
              January
            </th>
            <th colSpan="3" className="px-4 py-2 text-center border text-[#727272]">
              February
            </th>
            <th colSpan="3" className="px-4 py-2 text-center text-[#727272]">
              March
            </th>
          </>
        );
      case "April-June":
        return (
          <>
          
          <th colSpan="1" className="px-4 py-2 text-start border w-[38%]">
           
           </th>
            <th colSpan="3" className="px-4 py-2  text-center text-[#727272]">
              April
            </th>
            <th colSpan="3" className="px-4 py-2 text-center border text-[#727272]">
              May
            </th>
            <th colSpan="3" className="px-4 py-2 text-center text-[#727272]">
              June
            </th>
          </>
        );
      case "July-Sep":
        return (
          <>
       <th colSpan="1" className="px-4 py-2 text-start border w-[38%]">
           
           </th>
            <th colSpan="3" className="px-4 py-2  text-center text-[#727272]">
              July
            </th>
            <th colSpan="3" className="px-4 py-2 text-center border text-[#727272]">
              August
            </th>
            <th colSpan="3" className="px-4 py-2 text-center text-[#727272]">
              September
            </th>
          </>
        );
      case "Oct-Dec":
        return (
          <>
       
       <th colSpan="1" className="px-4 py-2 text-start border w-[38%]">
           
           </th>
            <th colSpan="3" className="px-4 py-2  text-center text-[#727272]">
              October
            </th>
            <th colSpan="3" className="px-4 py-2 text-center border text-[#727272]">
              November
            </th>
            <th colSpan="3" className="px-4 py-2 text-center text-[#727272]">
              December
            </th>
          </>
        );
      default:
        return null;
    }
  };
  const renderHeadings = () => {
    let headings = [];

    switch (selectedPeriod) {
      case "Jan-March":
        headings = ["Jan", "Feb", "March"];
        break;
      case "April-June":
        headings = ["April", "May", "June"];
        break;
      case "July-Sep":
        headings = ["July", "Aug", "Sep"];
        break;
      case "Oct-Dec":
        headings = ["Oct", "Nov", "Dec"];
        break;
      default:
        headings = [];
    }

    return headings.map((heading, index) => (
      <th
        key={index}
        className="px-12 py-2 text-center w-[40%] border-l border-r text-sm "
      >
        {heading}
      </th>
    ));
  };

  const renderData = () => {
    // Modify the data based on the selected period
    let data = [];

    switch (selectedPeriod) {
      case "Jan-March":
        data = [
          // Data for Jan-March
        ];
        break;
      case "April-June":
        data = [
          // Data for April-June
        ];
        break;
      case "July-Sep":
        data = [
          // Data for July-Sep
        ];
        break;
      case "Oct-Dec":
        data = [
          // Data for Oct-Dec
        ];
        break;
      default:
        data = [];
    }

    return data.map((row, rowIndex) => (
      <tr key={rowIndex}>
        {row.map((cell, cellIndex) => (
          <td key={cellIndex} className="px-2 py-2 text-sm border">
            {cell}
          </td>
        ))}
      </tr>
    ));
  };

  return (
    <>
    <div className="flex justify-between items-center shadow-md border-gray-100">
        <div
          className={`${
            collapsed ? "w-[90%] " : "w-[75%]"
          } flex justify-between items-center`}
        >
          <div className=" text-left mb-5 ml-6 mt-4">
            <p className="text-sm">Social</p>
            <p className="gradient-text text-[22px] font-bold">
            Parental Leave
            </p>
          </div>
          <div className="flex  absolute right-[4.5rem] mt-3">
            <button className="text-[#007EEF] bg-slate-200 rounded-full text-[11px] w-[60px] h-[25px]">
              GRI 302-1
            </button>
            <button className="text-[#fff] bg-amber-400 rounded-full text-[11px] w-[60px] h-[25px] ml-2 ">
              SDG 7
            </button>
            <button className="text-[#fff] bg-red-900 rounded-full text-[11px] w-[60px] h-[25px] ml-2">
              SDG 8
            </button>
            <button className="text-[#fff] bg-yellow-600 rounded-full text-[11px] w-[60px] h-[25px] ml-2 ">
              SDG 12
            </button>
            <button className="text-[#fff] bg-lime-900 rounded-full text-[11px] w-[60px] h-[25px] ml-2">
              SDG 13
            </button>
          </div>
        </div>
      </div>
    <div className="overflow-x-scroll rounded-md">
      <div className="flex justify-between">
        <h1 className="mx-4 pb-8 text-gray-500 text-xl font-bold mt-4">
          Parental Leave
        </h1>
     
      </div>
      <div className="w-full mx-4">
        <div className="flex mb-6">
        <button
              className={`${
                selectedPeriod === "Jan-March" ? "bg-blue-100" : "bg-white"
              } mr-2 px-2 text-xs py-1 border rounded `}
              onClick={() => handlePeriodChange("Jan-March")}
            >
              Jan-Mar
            </button>
            <button
              className={`${
                selectedPeriod === "April-June" ? "bg-blue-100" : "bg-white"
              } mr-2 px-2 text-xs py-1 border rounded`}
              onClick={() => handlePeriodChange("April-June")}
            >
              Apr-Jun
            </button>
            <button
              className={`${
                selectedPeriod === "July-Sep" ? "bg-blue-100" : "bg-white"
              } mr-2 px-2 text-xs py-1 border rounded`}
              onClick={() => handlePeriodChange("July-Sep")}
            >
              Jul-Sep
            </button>
            <button
              className={`${
                selectedPeriod === "Oct-Dec" ? "bg-blue-100" : "bg-white"
              } mr-2 px-2 text-xs py-1 border rounded`}
              onClick={() => handlePeriodChange("Oct-Dec")}
            >
              Oct-Dec
            </button>
     
        </div>
        <div
        className={`${
          collapsed ? "w-[98%] " : "w-[98%]"
        } shadow-lg rounded-[10px] mb-3`}
      >
       
        <table className="rounded-[10px]">
              {/* <div className="div">{renderHeadings()}</div> */}
              <thead className="border rounded-[10px]">

                <tr className="w-full border-t border-b gradient-background rounded-[10px]">
                  {renderTableHeaders()}
                </tr>

                <tr className="border-t border-b gradient-background">
                  {/* <td className="px-4 py-2  border">Employee Category</td> */}
             
                  <td className="px-2 py-2  border w-[10%]">
              
                  </td>
           
                  <td className="px-2 py-2 text-[14px] text-[#727272] border w-[10%] text-center">
                  Male
                  </td>
                  <td className="px-2 py-2 text-[14px] text-[#727272] border w-[11%] text-center">
                  Female
                  </td>
                  <td className="px-2 py-2 text-[14px] text-[#727272] border w-[10%] text-center">
                 Total
                  </td>
                  <td className="px-2 py-2 text-[14px] text-[#727272] border w-[10%] text-center">
                  Male
                  </td>
                  <td className="px-2 py-2 text-[14px] text-[#727272] border w-[11% ]text-center">
                  Female
                  </td>
                  <td className="px-2 py-2 text-[14px] text-[#727272] border w-[10%] text-center">
                 Total
                  </td>
                  <td className="px-2 py-2 text-[14px] text-[#727272] border w-[10%] text-center">
                  Male
                  </td>
                  <td className="px-2 py-2 text-[14px] text-[#727272] border w-[11%] text-center">
                  Female
                  </td>
                  <td className="px-2 py-2 text-[14px] text-[#727272] border w-[10%] text-center">
                 Total
                  </td>
                </tr>
              </thead>
              <tbody className="border-l border-r">
         
                  <React.Fragment>
           
                    <tr className="border-b">
                      {/* <td className="px-4 py-2 "></td> */}
                      <td className="px-2 py-2 text-[14px] text-[#727272] border-r">
                      Total number 
of employees that were entitled 
to parental leave, by gender.
                      </td>
                      {/* New column */}
                      <td className="py-2 text-center" >
                        <input
                          type="text"
                          placeholder="10"
                          autocomplete="off"
                          
                          className="py-1  w-[90%] m-[3px] outline-none  text-center text-[14px]"
                          // Other input props
                        />
                      </td>
                      <td className="py-2 text-center" >
                        <input
                          type="text"
                          placeholder="10"
                          // id="natureofOwnership"
                          name="natureofOwnership"
                          
                          autocomplete="off"
                          className="py-1  w-[90%] m-[3px] outline-none  text-center text-[14px]"
                          // value={formData.natureofOwnership}
                          // onChange={handleInputChange}
                        />
                      </td>
                      <td
                        className="py-2 border-r"
                      >
                        <input
                          type="text"
                          placeholder="10"
                          // id="natureofOwnership"
                          name="natureofOwnership"
                          
                          autocomplete="off"
                          className="py-1  w-[90%] m-[3px] outline-none text-center text-[14px]"
                          // value={formData.natureofOwnership}
                          // onChange={handleInputChange}
                        />
                      </td>
                      <td className="py-2 text-center" >
                        <input
                          type="text"
                          placeholder="10"
                          // id="natureofOwnership"
                          name="natureofOwnership"
                          
                          autocomplete="off"
                          className="py-1  w-[90%] m-[3px] outline-none text-center text-[14px]"
                          // value={formData.natureofOwnership}
                          // onChange={handleInputChange}
                        />
                      </td>
                      <td className="py-2 text-center" >
                        <input
                          type="text"
                          placeholder="10"
                          // id="natureofOwnership"
                          name="natureofOwnership"
                          autocomplete="off"
                          
                          className="py-1  w-[90%] m-[3px] outline-none text-center text-[14px]"
                          // value={formData.natureofOwnership}
                          // onChange={handleInputChange}
                        />
                      </td>
                      <td
                        className="py-2 border-r"
                      >
                        <input
                          type="text"
                          placeholder="10"
                          // id="natureofOwnership"
                          autocomplete="off"
                          
                          name="natureofOwnership"
                          className="py-1  w-[90%] m-[3px] outline-none text-center text-[14px]"
                          // value={formData.natureofOwnership}
                          // onChange={handleInputChange}
                        />
                      </td>
                      <td className="py-2 text-center" >
                        <input
                          type="text"
                          placeholder="10"
                          // id="natureofOwnership"
                          name="natureofOwnership"
                          autocomplete="off"
                          
                          className="py-1  w-[90%] m-[3px] outline-none text-center text-[14px]"
                          // value={formData.natureofOwnership}
                          // onChange={handleInputChange}
                        />
                      </td>
                      <td className="py-2 text-center" >
                        <input
                          type="text"
                          placeholder="10"
                          // id="natureofOwnership"
                          name="natureofOwnership"
                          autocomplete="off"
                          
                          className="py-1  w-[90%] m-[3px] outline-none text-center text-[14px]"
                          // value={formData.natureofOwnership}
                          // onChange={handleInputChange}
                        />
                      </td>
                      <td className="py-2 text-center" >
                        <input
                          type="text"
                          placeholder="10"
                          // id="natureofOwnership"
                          autocomplete="off"
                          name="natureofOwnership"
                          
                          className="py-1  w-[90%] m-[3px] outline-none text-center text-[14px]"
                          // value={formData.natureofOwnership}
                          // onChange={handleInputChange}
                        />
                      </td>
                    </tr>
                    <tr className="border-b">
                    <td className="px-2 py-2 text-[14px] text-[#727272] border-r">
                    Total number of employees that took 
parental leave, by gender.
                      </td>
                   
                      {/* New column */}
                      <td className="py-2 text-center" >
                        <input
                          type="text"
                          placeholder="10"
                          // id="natureofOwnership"
                          name="natureofOwnership"
                          
                          autocomplete="off"
                          className="py-1  w-[90%] m-[3px] outline-none  text-center text-[14px]"
                          // value={formData.natureofOwnership}
                          // onChange={handleInputChange}
                        />
                      </td>
                      <td className="py-2 text-center" >
                        <input
                          type="text"
                          placeholder="10"
                          // id="natureofOwnership"
                          name="natureofOwnership"
                          autocomplete="off"
                          
                          className="py-1  w-[90%] m-[3px] outline-none  text-center text-[14px]"
                          // value={formData.natureofOwnership}
                          // onChange={handleInputChange}
                        />
                      </td>
                      <td
                        className="py-2 border-r"
                      >
                        <input
                          type="text"
                          placeholder="10"
                          // id="natureofOwnership"
                          name="natureofOwnership"
                          autocomplete="off"
                          
                          className="py-1  w-[90%] m-[3px] outline-none  text-center text-[14px] "
                          // value={formData.natureofOwnership}
                          // onChange={handleInputChange}
                        />
                      </td>
                      <td className="py-2 text-center" >
                        <input
                          type="text"
                          placeholder="10"
                          // id="natureofOwnership"
                          name="natureofOwnership"
                          autocomplete="off"
                          
                          className="py-1  w-[90%] m-[3px] outline-none  text-center text-[14px]"
                          // value={formData.natureofOwnership}
                          // onChange={handleInputChange}
                        />
                      </td>
                      <td className="py-2 text-center" >
                        <input
                          type="text"
                          placeholder="10"
                          // id="natureofOwnership"
                          name="natureofOwnership"
                          autocomplete="off"
                          
                          className="py-1  w-[90%] m-[3px] outline-none  text-center text-[14px]"
                          // value={formData.natureofOwnership}
                          // onChange={handleInputChange}
                        />
                      </td>
                      <td
                        className="py-2 border-r"
                      >
                        <input
                          type="text"
                          placeholder="10"
                          // id="natureofOwnership"
                          name="natureofOwnership"
                          autocomplete="off"
                          
                          className="py-1  w-[90%] m-[3px] outline-none  text-center text-[14px]"
                          // value={formData.natureofOwnership}
                          // onChange={handleInputChange}
                        />
                      </td>
                      <td className="py-2 text-center" >
                        <input
                          type="text"
                          placeholder="10"
                          // id="natureofOwnership"
                          name="natureofOwnership"
                          autocomplete="off"
                          
                          className="py-1  w-[90%] m-[3px] outline-none  text-center text-[14px]"
                          // value={formData.natureofOwnership}
                          // onChange={handleInputChange}
                        />
                      </td>
                      <td className="py-2 text-center" >
                        <input
                          type="text"
                          placeholder="10"
                          // id="natureofOwnership"
                          name="natureofOwnership"
                          autocomplete="off"
                          
                          className="py-1  w-[90%] m-[3px] outline-none  text-center text-[14px]"
                          // value={formData.natureofOwnership}
                          // onChange={handleInputChange}
                        />
                      </td>
                      <td className="py-2 text-center" >
                        <input
                          type="text"
                          placeholder="10"
                          // id="natureofOwnership"
                          name="natureofOwnership"
                          autocomplete="off"
                          
                          className="py-1  w-[90%] m-[3px] outline-none text-center text-[14px]"
                          // value={formData.natureofOwnership}
                          // onChange={handleInputChange}
                        />
                      </td>
                    </tr>
                    <tr className="border-b">
                    <td className="px-2 py-2 text-[14px] text-[#727272] border-r">
                    Total number of employees that returned 
to work in the reporting period after 
parental leave ended, by gender
                      </td>
                      <td className="py-2 text-center" >
                        <input
                          type="text"
                          placeholder="10"
                          // id="natureofOwnership"
                          name="natureofOwnership"
                          autocomplete="off"
                          
                          className="py-1  w-[90%] m-[3px] outline-none text-center text-[14px]"
                          // value={formData.natureofOwnership}
                          // onChange={handleInputChange}
                        />
                      </td>
                      <td className="py-2 text-center" >
                        <input
                          type="text"
                          placeholder="10"
                          // id="natureofOwnership"
                          autocomplete="off"
                          name="natureofOwnership"
                          
                          className="py-1  w-[90%] m-[3px] outline-none text-center text-[14px]"
                          // value={formData.natureofOwnership}
                          // onChange={handleInputChange}
                        />
                      </td>
                      <td
                        className={`py-2 border-r`}
                      >
                        <input
                          type="text"
                          placeholder="10"
                          // id="natureofOwnership"
                          autocomplete="off"
                          name="natureofOwnership"
                          
                          className="py-1  w-[90%] m-[3px] outline-none text-center text-[14px]"
                          // value={formData.natureofOwnership}
                          // onChange={handleInputChange}
                        />
                      </td>
                      <td className="py-2 text-center" >
                        <input
                          type="text"
                          placeholder="10"
                          // id="natureofOwnership"
                          name="natureofOwnership"
                          autocomplete="off"
                          
                          className="py-1  w-[90%] m-[3px] outline-none text-center text-[14px]"
                          // value={formData.natureofOwnership}
                          // onChange={handleInputChange}
                        />
                      </td>
                      <td className="py-2 text-center" >
                        <input
                          type="text"
                          placeholder="10"
                          // id="natureofOwnership"
                          name="natureofOwnership"
                          autocomplete="off"
                          
                          className="py-1  w-[90%] m-[3px] outline-none text-center text-[14px]"
                          // value={formData.natureofOwnership}
                          // onChange={handleInputChange}
                        />
                      </td>
                      <td
                        className="py-2 border-r"
                      >
                        <input
                          type="text"
                          placeholder="10"
                          // id="natureofOwnership"
                          name="natureofOwnership"
                          autocomplete="off"
                          
                          className="py-1  w-[90%] m-[3px] outline-none text-center text-[14px]"
                          // value={formData.natureofOwnership}
                          // onChange={handleInputChange}
                        />
                      </td>
                      <td className="py-2 text-center" >
                        <input
                          type="text"
                          placeholder="10"
                          // id="natureofOwnership"
                          name="natureofOwnership"
                          autocomplete="off"
                          
                          className="py-1  w-[90%] m-[3px] outline-none text-center text-[14px]"
                          // value={formData.natureofOwnership}
                          // onChange={handleInputChange}
                        />
                      </td>
                      <td className="py-2 text-center" >
                        <input
                          type="text"
                          placeholder="10"
                          // id="natureofOwnership"
                          name="natureofOwnership"
                          autocomplete="off"
                          
                          className="py-1  w-[90%] m-[3px] outline-none text-center text-[14px]"
                          // value={formData.natureofOwnership}
                          // onChange={handleInputChange}
                        />
                      </td>
                      <td className= "py-2">
                        <input
                          type="text"
                          placeholder="10"
                          // id="natureofOwnership"
                          autocomplete="off"
                          
                          name="natureofOwnership"
                          className="py-1  w-[90%] m-[3px] outline-none text-center text-[14px]"
                          // value={formData.natureofOwnership}
                          // onChange={handleInputChange}
                        />
                      </td>
                    </tr>
                    <tr className="border-b">
                    <td className="px-2 py-2 text-[14px] text-[#727272] border-r">
                    Total number of employees that returned to 
work after parental leave ended that were 
still employed 12 months after their return to 
work, by gender"
                      </td>
                      <td className="py-2 text-center" >
                        <input
                          type="text"
                          placeholder="10"
                          // id="natureofOwnership"
                          name="natureofOwnership"
                          autocomplete="off"
                          
                          className="py-1  w-[90%] m-[3px] outline-none text-center text-[14px]"
                          // value={formData.natureofOwnership}
                          // onChange={handleInputChange}
                        />
                      </td>
                      <td className="py-2 text-center" >
                        <input
                          type="text"
                          placeholder="10"
                          // id="natureofOwnership"
                          autocomplete="off"
                          name="natureofOwnership"
                          
                          className="py-1  w-[90%] m-[3px] outline-none text-center text-[14px]"
                          // value={formData.natureofOwnership}
                          // onChange={handleInputChange}
                        />
                      </td>
                      <td
                        className={`py-2 border-r`}
                      >
                        <input
                          type="text"
                          placeholder="10"
                          // id="natureofOwnership"
                          autocomplete="off"
                          name="natureofOwnership"
                          
                          className="py-1  w-[90%] m-[3px] outline-none text-center text-[14px]"
                          // value={formData.natureofOwnership}
                          // onChange={handleInputChange}
                        />
                      </td>
                      <td className="py-2 text-center" >
                        <input
                          type="text"
                          placeholder="10"
                          // id="natureofOwnership"
                          name="natureofOwnership"
                          autocomplete="off"
                          
                          className="py-1  w-[90%] m-[3px] outline-none text-center text-[14px]"
                          // value={formData.natureofOwnership}
                          // onChange={handleInputChange}
                        />
                      </td>
                      <td className="py-2 text-center" >
                        <input
                          type="text"
                          placeholder="10"
                          // id="natureofOwnership"
                          name="natureofOwnership"
                          autocomplete="off"
                          
                          className="py-1  w-[90%] m-[3px] outline-none text-center text-[14px]"
                          // value={formData.natureofOwnership}
                          // onChange={handleInputChange}
                        />
                      </td>
                      <td
                        className="py-2 border-r"
                      >
                        <input
                          type="text"
                          placeholder="10"
                          // id="natureofOwnership"
                          name="natureofOwnership"
                          autocomplete="off"
                          
                          className="py-1  w-[90%] m-[3px] outline-none text-center text-[14px]"
                          // value={formData.natureofOwnership}
                          // onChange={handleInputChange}
                        />
                      </td>
                      <td className="py-2 text-center" >
                        <input
                          type="text"
                          placeholder="10"
                          // id="natureofOwnership"
                          name="natureofOwnership"
                          autocomplete="off"
                          
                          className="py-1  w-[90%] m-[3px] outline-none text-center text-[14px]"
                          // value={formData.natureofOwnership}
                          // onChange={handleInputChange}
                        />
                      </td>
                      <td className="py-2 text-center" >
                        <input
                          type="text"
                          placeholder="10"
                          // id="natureofOwnership"
                          name="natureofOwnership"
                          autocomplete="off"
                          
                          className="py-1  w-[90%] m-[3px] outline-none text-center text-[14px]"
                          // value={formData.natureofOwnership}
                          // onChange={handleInputChange}
                        />
                      </td>
                      <td className= "py-2">
                        <input
                          type="text"
                          placeholder="10"
                          // id="natureofOwnership"
                          autocomplete="off"
                          
                          name="natureofOwnership"
                          className="py-1  w-[90%] m-[3px] outline-none text-center text-[14px]"
                          // value={formData.natureofOwnership}
                          // onChange={handleInputChange}
                        />
                      </td>
                    </tr>
                  </React.Fragment>
               
              </tbody>
            </table>
        </div>
      </div>
    </div>
    </>
  );
};

export default ParentalLeave;
