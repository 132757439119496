import React, { useState, useEffect } from "react";
import { KeyboardArrowUpOutlined } from "@mui/icons-material";
import Tooltip from "@mui/material/Tooltip";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import KeyboardArrowDownOutlinedIcon from "@mui/icons-material/KeyboardArrowDownOutlined";
import Purchased from "./Purchased/purchased";
import Consumedfuel from "./Consumed-fuel/Consumed-fuel";
import Selfgenerated from "./Self-generated/Self-generated";
import Energysold from "./Energy-sold/Energy-sold";
import { useDispatch, useSelector } from "react-redux";

const AccordionItem = ({ title, children, tooltiptext }) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div className="shadow-md py-1 px-6 mb-4 rounded-[8px] cursor-pointer border border-b-3 border-neutral-200 w-[96%]">
      <button
        className="py-3 px-4 w-full text-left flex"
        onClick={() => setIsOpen(!isOpen)}
      >
        <div>
          <h5 className="font-semibold text-[14px] pt-1">{title}</h5>
        </div>
        <div className="ml-5">
          <Tooltip
            title={tooltiptext}
            arrow
            placement="top"
            componentsProps={{
              tooltip: {
                sx: {
                  backgroundColor: "#000",
                  color: "white",
                  fontSize: "12px",
                  boxShadow: 3,
                  borderRadius: "8px",
                },
              },
              arrow: {
                sx: {
                  color: "#000",
                },
              },
            }}
          >
            <InfoOutlinedIcon sx={{ fontSize: "14px", mt: 0.6 }} />
          </Tooltip>
        </div>
        <div className="absolute right-[4.5rem]">
          {" "}
          <span>
            {isOpen ? (
              <KeyboardArrowUpOutlined />
            ) : (
              <KeyboardArrowDownOutlinedIcon />
            )}
          </span>
        </div>
      </button>
      {isOpen && <div className="p-4">{children}</div>}
    </div>
  );
};

const Energyconsumption = () => {
  const quarter = useSelector((state) => state.emission?.quarter);
  const [monthsCalculated, setMonthsCalculated] = useState([]);
  const [calculated, setCalculated] = useState(false);

  if (quarter == "JAN") {
    var month = "JANUARY";
  } else if (quarter == "FEB") {
    var month = "FEBRUARY";
  } else if (quarter == "MAR") {
    var month = "MARCH";
  } else if (quarter == "APR") {
    var month = "APRIL";
  } else if (quarter == "MAY") {
    var month = "MAY";
  } else if (quarter == "JUN") {
    var month = "JUN";
  } else if (quarter == "JUL") {
    var month = "JULY";
  } else if (quarter == "AUG") {
    var month = "AUGUSR";
  } else if (quarter == "SEP") {
    var month = "SEPTEMBER";
  } else if (quarter == "OCT") {
    var month = "OCTOBER";
  } else if (quarter == "NOV") {
    var month = "NOVEMBER";
  } else if (quarter == "DEC") {
    var month = "DECEMBER";
  }
  return (
    <>
      <div className="mx-3">
        <AccordionItem
          title="Purchased  heating, cooling, electricity and steam"
          tooltiptext="This section documents data corresponding to Energy 
               Purchased for use within the Organization.
               Include: 
               Input data related only to directly purchased 
               heating, electricity, steam and cooling inside the organization. 
               Exclude: Do not include data relating to fuel consumption."
        >
          <div className="mb-5 flex">
            <div className="h-[1.2rem] px-2 py-1 opacity-80 bg-gradient-to-r from-[#007EEF] to-[#2AE4FF] rounded-sm justify-start items-start gap-2.5 inline-flex">
              <div className="text-white text-[12px] font-bold uppercase leading-none">
                {month}
              </div>
            </div>
            <div className="absolute right-[4.5rem]">
              <button className="text-[#727272] bg-slate-200 rounded-full text-[11px] w-[60px] h-[19px]">
                302-1c
              </button>
            </div>
          </div>
          <Purchased />
        </AccordionItem>
        <AccordionItem
          title="Consumed fuel and energy"
          tooltiptext="This section documents data corresponding to Energy 
        Purchased for use within the Organization.
        Include: 
        Input data related only to directly purchased 
        heating, electricity, steam and cooling inside the organization. 
        Exclude: Do not include data relating to fuel consumption."
        >
          <div className="mb-5 flex">
            <div className="h-[1.2rem] px-2 py-1 opacity-80 bg-gradient-to-r from-[#007EEF] to-[#2AE4FF] rounded-sm justify-start items-start gap-2.5 inline-flex">
              <div className="text-white text-[12px] font-bold uppercase leading-none">
                {month}
              </div>
            </div>
            <div className="absolute right-[4.5rem]">
              <button className="text-[#727272] bg-slate-200 rounded-full text-[11px] w-[60px] h-[19px]">
                302-1c
              </button>
            </div>
          </div>
          <Consumedfuel />
        </AccordionItem>
        <AccordionItem
          title="Self generated - not consumed "
          tooltiptext="This section documents data corresponding to Energy 
        Purchased for use within the Organization.
        Include: 
        Input data related only to directly purchased 
        heating, electricity, steam and cooling inside the organization. 
        Exclude: Do not include data relating to fuel consumption."
        >
          <div className="mb-5 flex">
            <div className="h-[1.2rem] px-2 py-1 opacity-80 bg-gradient-to-r from-[#007EEF] to-[#2AE4FF] rounded-sm justify-start items-start gap-2.5 inline-flex">
              <div className="text-white text-[12px] font-bold uppercase leading-none">
                {month}
              </div>
            </div>
            <div className="absolute right-[4.5rem]">
              <button className="text-[#727272] bg-slate-200 rounded-full text-[11px] w-[60px] h-[19px]">
                302-1c
              </button>
            </div>
          </div>
          <Selfgenerated />
        </AccordionItem>
        <AccordionItem
          title="Energy sold"
          tooltiptext="This section documents data corresponding to Energy 
        Purchased for use within the Organization.
        Include: 
        Input data related only to directly purchased 
        heating, electricity, steam and cooling inside the organization. 
        Exclude: Do not include data relating to fuel consumption."
        >
          <div className="mb-5 flex">
            <div className="h-[1.2rem] px-2 py-1 opacity-80 bg-gradient-to-r from-[#007EEF] to-[#2AE4FF] rounded-sm justify-start items-start gap-2.5 inline-flex">
              <div className="text-white text-[12px] font-bold uppercase leading-none">
                {month}
              </div>
            </div>
            <div className="absolute right-[4.5rem]">
              <button className="text-[#727272] bg-slate-200 rounded-full text-[11px] w-[60px] h-[19px]">
                302-1c
              </button>
            </div>
          </div>
          <Energysold />
        </AccordionItem>

        {/* Add more accordion items here */}
      </div>
      <div className="flex justify-end items-center mt-[24] me-[51px]">
        <button className="w-[150px] h-8 px-[22px] py-2 bg-[#007EEF] rounded shadow flex-col justify-center items-center inline-flex text-white text-xs font-bold leading-[15px]">
          <div className="cursor-pointer">
            <p className="text-white text-sm font-semibold">Save</p>
            {/* {calculating
          ? 'Calculating...'
          : calculated
          ? 'Calculated'
          : 'Calculate'} */}
          </div>
        </button>
      </div>
    </>
  );
};

export default Energyconsumption;
