import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useProSidebar } from 'react-pro-sidebar';
import { setRegulation } from 'state/preferenceSlice';
import { regulationArray } from 'components/data/regulation';
import LogoCard from 'components/LogoCard.jsx';

const Regulation = () => {
  const regulation = useSelector((state) => state.preference.regulation);
  const [selected, setSelected] = useState(regulation);
  const [regulationData, setRegulationData] = useState(regulationArray);
  const dispatch = useDispatch();
  const { collapsed } = useProSidebar();

  const toggleSelect = (id) => {
    const updatedSelected = selected.includes(id)
      ? selected.filter((item) => item !== id)
      : [...selected, id];

    setSelected(updatedSelected);
    dispatch(setRegulation(updatedSelected));
  };

  console.log({ regulation });
  return (
    <div className='px-6 mt-6'>
      <h2 className='text-2xl font-bold mb-8 text-gray-700'>Select Regulations</h2>
      <div
        className={`grid ${
          collapsed ? 'grid-cols-6' : 'grid-cols-6'
        } gap-6 m-6`}
      >
        {regulationData.map((data) => {
          console.log(data);
          return (
            <LogoCard
              data={data}
              isSelected={selected.includes(data.id)}
              onToggleSelect={toggleSelect} // Updated prop name
            />
          );
        })}
      </div>
    </div>
  );
};

export default Regulation;
