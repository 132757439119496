import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useProSidebar } from 'react-pro-sidebar';
import { frameworksArray } from 'components/data/frameworks';
import LogoCard from 'components/LogoCard';
import { setFrameworks } from 'state/preferenceSlice';

const Frameworks = () => {
  const frameworks = useSelector((state) => state.preference.frameworks);
  const [selected, setSelected] = useState(frameworks);
  const [frameworksData, setFrameworksData] = useState(frameworksArray);
  const dispatch = useDispatch();

  const toggleSelect = (id) => {
    const updatedSelected = selected.includes(id)
      ? selected.filter((item) => item !== id)
      : [...selected, id];

    setSelected(updatedSelected);
    dispatch(setFrameworks(updatedSelected));
  };

  const { collapsed } = useProSidebar();
  return (
    <div className='px-6 mt-6'>
      <h2 className='text-2xl font-bold mb-8 text-gray-700'>
        Select Frameworks
      </h2>
      <div
        className={`grid ${
          collapsed ? 'grid-cols-6' : 'grid-cols-6'
        } gap-6 gap-y-14 m-6`}
      >
        {frameworksData.map((data) => {
          console.log(data);
          return (
            <LogoCard
              data={data}
              isSelected={selected.includes(data.id)}
              onToggleSelect={toggleSelect}
            />
          );
        })}
      </div>
    </div>
  );
};

export default Frameworks;
