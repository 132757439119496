import gri from "assets/frameworks/GRI.svg";
import cdp from "assets/frameworks/CDP.png";
import sebi from "assets/frameworks/SEBI.png";
import sasb from "assets/frameworks/SASB.png";
import tcfd from "assets/frameworks/TCFD.svg";
import unpri from "assets/frameworks/UNPRI.png";

export const frameworksArray = [
  {
    id: 1,
    imgSource: gri,
    label: "GRI: With reference to",
    size: 36,
  },
  {
    id: 2,
    imgSource: gri,
    label: "GRI: In accordance with",
    size: 36,
  },
  {
    id: 3,
    imgSource: cdp,
    label: "CDP",
    size: 56,
  },
  {
    id: 4,
    imgSource: sebi,
    label: "BRSR",
    size: 36,
  },
  {
    id: 5,
    imgSource: sasb,
    label: "SASB",
    size: 36,
  },
  {
    id: 6,
    imgSource: tcfd,
    label: "TCFD",
    size: 36,
  },

  {
    id: 7,
    imgSource: unpri,
    label: "UNPRI",
    size: 40,
  },
];
