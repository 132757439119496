import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import axios from 'axios';
import {
  InfoOutlined,
  KeyboardArrowDownOutlined,
  LocationOnOutlined,
} from '@mui/icons-material';
import { CalendarMonthOutlined } from '@mui/icons-material';
import { yearInfo } from 'components/data/headerInfo';
import {
  setLocation,
  setYear,
  setQuarter,
  setCountryCode,
} from 'state/emissionSlice';
import MonthButton from 'components/MonthButton';
import { useNavigate } from 'react-router-dom';
import { setRowsStateNew } from 'state/emissionSlice';

const Header = ({ monthsCalculated, error, monthlyEmissions }) => {
  const dispatch = useDispatch();

  const token = useSelector((state) => state.auth.authToken);
  const location = useSelector((state) => state.emission.location);

  let axiosConfig = {
    headers: {
      Authorization: 'Bearer ' + token,
      'Content-type': 'application/json',
    },
  };

  // locations

  const [locations, setLocations] = useState([]);
  const [selectedLocation, setSelectedLocation] = useState({});
  const [selectedYear, setSelectedYear] = useState('');
  const [loc, setLoc] = useState('');

  const navigate = useNavigate();

  const year = useSelector((state) => state.emission.year);
  useEffect(() => {
    setSelectedYear(year);
  }, [year]);

  const headers = {
    'Content-Type': 'application/json',
    Authorization: 'Bearer ' + token,
  };

  useEffect(() => {
    fetchHierarchy();
  }, []);

  function fetchHierarchy() {
    axios
      .get(`${process.env.REACT_APP_BACKEND_URL}/structure`, axiosConfig)
      .then((response) => {
        const data = response.data;
        const filtered = filterData(data);
        console.log(filtered);
        setLocations(filtered);
        setSelectedLocation(filtered[0]);
      })
      .catch((error) => {
        console.error('Error fetching data: ', error);
      });
  }

  function filterData(data) {
    const extractedData = data.reduce((acc, item) => {
      const locations = item.corporatenetityorg.flatMap((entity) =>
        entity.location.map((location) => ({
          id: location.id,
          name: location.name,
          Address: location.address,
          Country: location.country,
          state: location.state,
          revenue: location.revenue,
          amount: location.amount,
          area: location.area,
          sector: location.sector,
          City: location.city,
          'Corporate Entity': location.corporate_entity,
          businessActivities: location.type_of_business_activities,
          productTypes: location.type_of_product,
          serviceTypes: location.type_of_services,
        }))
      );
      return [...acc, ...locations];
    }, []);

    return extractedData;
  }

  //quarter
  const quarter = useSelector((state) => state.emission.quarter);
  const months = [
    'JAN',
    'FEB',
    'MAR',
    'APR',
    'MAY',
    'JUN',
    'JUL',
    'AUG',
    'SEP',
    'OCT',
    'NOV',
    'DEC',
  ];

  const handleMonthClick = (month) => {
    dispatch(setQuarter(month));
  };
  // const handleMonthClick = (month) => {
  //   setTimeout(() => {
  //     dispatch(setQuarter(month));
  //   }, 2000); 
  // };

  const handleLocationChange = (event) => {
    const location = event.target.value;
    dispatch(setLocation(location));
    setLoc(location);
    const foundLocation = locations.find((loc) => loc.name === location);
    setSelectedLocation(foundLocation);

    const code = foundLocation?.Country;
    console.log({ foundLocation });
    dispatch(setCountryCode(code));
    dispatchRowSet();
  };

  const handleYearChange = (event) => {
    const year = event.target.value;
    dispatch(setYear(year));
    setSelectedYear(year);
    dispatchRowSet();
  };

  const dispatchRowSet = () => {
    dispatch(
      setRowsStateNew({
        quarter,
        scope: 1,
        rows: [
          {
            category: '',
            subCategory: '',
            activities: [],
            value1: '',
            value2: '',
            unit: ['', ''],
            file: null,
            fileName: '',
            modifiedTime: '',
            activity: '',
            selectedActivity: {},
            unitType: '',
            assignTo: '',
            uploadedBy: '',
          },
        ],
      })
    );
    dispatch(
      setRowsStateNew({
        quarter,
        scope: 2,
        rows: [
          {
            category: '',
            subCategory: '',
            activities: [],
            value1: '',
            value2: '',
            unit: ['', ''],
            file: null,
            fileName: '',
            modifiedTime: '',
            activity: '',
            selectedActivity: {},
            unitType: '',
            assignTo: '',
            uploadedBy: '',
          },
        ],
      })
    );
    dispatch(
      setRowsStateNew({
        quarter,
        scope: 3,
        rows: [
          {
            category: '',
            subCategory: '',
            activities: [],
            value1: '',
            value2: '',
            unit: ['', ''],
            file: null,
            fileName: '',
            modifiedTime: '',
            activity: '',
            selectedActivity: {},
            unitType: '',
            assignTo: '',
            uploadedBy: '',
          },
        ],
      })
    );
  };

  return (
    <div className='flex flex-col justify-start ml-2 mb-14'>
      <h1 className='gradient-text mb-4 text-[1.375rem] font-bold'>
        Emissions
      </h1>
      <h5 className='font-lg font-medium text-gray-500 text-[0.625rem] -mt-4 mb-6'>
        <InfoOutlined style={{ fontSize: '0.625rem' }} />
        The data that falls under emission category are uploaded here.
      </h5>
      <div>
        <div className='flex space-x-4'>
          <div className='relative'>
            <div className='absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none'>
              <LocationOnOutlined
                className='text-neutral-500'
                style={{ fontSize: '16px' }}
              />
            </div>
            <select
              className={`border m-0.5 text-sm text-neutral-500 appearance-none pr-8 rounded-md py-2 pl-10 leading-tight focus:outline-none focus:bg-white focus:border-gray-500 ${
                error ? 'border-red-500' : ''
              }`}
              value={location || ''}
              onChange={handleLocationChange}
            >
              <option value=''>Select Location</option>
              {locations.map(({ name }) => (
                <option value={name}>{name}</option>
              ))}
            </select>
            <div className='absolute inset-y-0 right-2 flex items-center pl-3 pointer-events-none'>
              <KeyboardArrowDownOutlined
                className='text-neutral-500'
                style={{ fontSize: '16px' }}
              />
            </div>
            {error && (
              <span className='text-red-500 absolute top-10 left-2 text-xs'>
                Please select a location
              </span>
            )}
          </div>
          <div className='relative'>
            <div className='absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none'>
              <CalendarMonthOutlined
                className='text-neutral-500'
                style={{ fontSize: '16px' }}
              />
            </div>
            <select
              className='w-[112px] border appearance-none text-sm text-neutral-500 m-0.5 pr-8 rounded-md py-2 pl-10 leading-tight focus:outline-none focus:bg-white focus:border-gray-500 cursor-pointer'
              value={selectedYear}
              onChange={handleYearChange}
            >
              <option value=''>Select Year</option>
              {yearInfo.map((item) => (
                <option value={item.slice(0, 4)}>{item.slice(0, 4)}</option>
              ))}
            </select>
            <div className='absolute inset-y-0 right-2 flex items-center pl-3 pointer-events-none'>
              <KeyboardArrowDownOutlined
                className='text-neutral-500'
                style={{ fontSize: '16px' }}
              />
            </div>
          </div>
          <div className='absolute -right-[6rem] w-[400px] h-6 px-1 py-[3px] bg-white bg-opacity-10 rounded-lg justify-start items-center inline-flex'>
            <div className='w-[290px]'>
              <span className="text-sky-700 text-[13px] font-normal font-['Manrope'] leading-none">
                GHG Emissions for the month ={' '}
              </span>
              <span className="green-gradient-to-r text-[13px] font-semibold font-['Manrope'] leading-none">
                {/* {monthlyEmissions === null ? 0 : monthlyEmissions} tCO */}
                {monthlyEmissions} tCO
                <sub className='green-gradient-to-r'>2</sub>e
              </span>
            </div>
          </div>
        </div>
        <div className='flex justify-center items-center mt-8  w-[775px] h-9 rounded-md'>
          {months.map((month) => (
            <MonthButton
              month={month}
              handleMonthClick={handleMonthClick}
              monthsCalculated={monthsCalculated}
            />
          ))}
        </div>
      </div>
      <div></div>
    </div>
  );
};

export default Header;
