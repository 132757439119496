import React, { useState } from "react";
import { useProSidebar } from "react-pro-sidebar";

const EmployeeBenefits = () => {
  const [tableData, setTableData] = useState([
    ["Category", "Management", "Company"],
    ["Life Insurance", true, true],
    ["Disability & Invalidity Coverage", true, true],
    ["Parental Leave", true, true],
    ["Retirement Provision", true, true],
    ["Stock Ownership", true, true],
    ["Others", true, true],
  ]);

  const addBenefit = () => {
    setTableData((prevData) => {
      const newData = [...prevData];
      const newRow = new Array(newData[0].length).fill("");
      newData.push(newRow);
      return newData;
    });
  };

  const addCategory = () => {
    setTableData((prevData) => {
      const newData = prevData.map((row, rowIndex) => {
        if (rowIndex === 0) {
          return [...row, ""];
        } else {
          return [...row, true];
        }
      });
      return newData;
    });
  };

  const handleCellChange = (value, rowIndex, cellIndex) => {
    setTableData((prevData) => {
      const newData = [...prevData];
      if (rowIndex === 0 || cellIndex === 0) {
        newData[rowIndex][cellIndex] = value;
      } else {
        newData[rowIndex][cellIndex] = {
          category: value,
          management: value,
          company: value,
        };
      }
      return newData;
    });
  };
  const { collapseSidebar, collapsed } = useProSidebar();
  return (
 <>
  <div className="flex justify-between items-center shadow-md border-gray-100">
        <div
          className={`${
            collapsed ? "w-[90%] " : "w-[75%]"
          } flex justify-between items-center`}
        >
          <div className=" text-left mb-5 ml-6 mt-4">
            <p className="text-sm">Social</p>
            <p className="gradient-text text-[22px] font-bold">
            Benefits
            </p>
          </div>
          <div className="flex  absolute right-[4.5rem] mt-3">
            <button className="text-[#007EEF] bg-slate-200 rounded-full text-[11px] w-[60px] h-[25px]">
              GRI 302-1
            </button>
            <button className="text-[#fff] bg-amber-400 rounded-full text-[11px] w-[60px] h-[25px] ml-2 ">
              SDG 7
            </button>
            <button className="text-[#fff] bg-red-900 rounded-full text-[11px] w-[60px] h-[25px] ml-2">
              SDG 8
            </button>
            <button className="text-[#fff] bg-yellow-600 rounded-full text-[11px] w-[60px] h-[25px] ml-2 ">
              SDG 12
            </button>
            <button className="text-[#fff] bg-lime-900 rounded-full text-[11px] w-[60px] h-[25px] ml-2">
              SDG 13
            </button>
          </div>
        </div>
      </div>
      <div className="w-full mt-4">
        <h1 className="mx-4 pb-8 text-gray-500 text-xl font-bold">
          Employee Benefits
        </h1>
  
      </div>
      <div className=" w-full mx-1">
        <table className="mb-5 w-full text-xs text-start shadow-lg rounded-md px-2">
          <thead className="gradient-background h-[40px] ">
            <tr>
              {tableData[0].map((cell, index) => (
                <th
                  key={index}
                  className="text-gray-900 text-left pl-2"
                >
                   {/* {cell} */}
                   <input
                    type="text"
                    className="w-full py-1 bg-transparent"
                    placeholder="Enter a text"
                    autocomplete="off"
                    value={cell}
                    onChange={(e) => handleCellChange(e.target.value, 0, index)}
                  />
                </th>
              ))}
              <th >
                <button
                  className="text-xs opacity-70 text-blue-600"
                  onClick={addCategory}
                >
                  + New category
                </button>
              </th>
            </tr>
          </thead>
          <tbody>
            {tableData.slice(1).map((row, rowIndex) => (
              <tr key={rowIndex + 1} >
                {row.map((cell, cellIndex) => (
                  <td
                    key={cellIndex}
                    className="py-2 font-bold text-gray-600 pl-2"
                  >
                    {cellIndex === 0 ? (
                      <input
                        type="text"
                        placeholder="Enter a text"
                        className="w-full py-1 "
                        value={cell}
                        onChange={(e) =>
                          handleCellChange(
                            e.target.value,
                            rowIndex + 1,
                            cellIndex
                          )
                        }
                      />
                    ) : (
                      <input
                        type="checkbox"
                        className="w-full ms-3"
                        checked={cell.management && cell.company}
                        onChange={(e) =>
                          handleCellChange(
                            e.target.checked,
                            rowIndex + 1,
                            cellIndex
                          )
                        }
                      />
                    )}
                  </td>
                ))}
              </tr>
            ))}
            <tr>
              <td className="pl-2 py-2">
                <button
                  className="mb-2 text-blue-600 font-bold py-2 px-4 rounded text-xs opacity-70 border border-[#007EEF]"
                  onClick={addBenefit}
                >
                  + Add new category
                </button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      </>
  );
};

export default EmployeeBenefits;
