import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import SDGCard from 'components/SDGCard2';
import { sdgdata } from 'components/data/SDG';
import { setSdgs } from 'state/preferenceSlice';
import { useProSidebar } from 'react-pro-sidebar';

const SDGCards = () => {
  const sdgs = useSelector((state) => state.preference.sdgs);
  const [selected, setSelected] = useState(sdgs);
  const [SDGData, setSDGData] = useState(sdgdata);
  const dispatch = useDispatch();
  const { collapsed } = useProSidebar();

  const toggleSelect = (id) => {
    const updatedSelected = selected.includes(id)
      ? selected.filter((item) => item !== id)
      : [...selected, id];

    setSelected(updatedSelected);
    dispatch(setSdgs(updatedSelected));
  };

  console.log(sdgs, selected);

  return (
    <div className='px-6 mt-6'>
      <h2 className='text-2xl font-bold mb-8 text-gray-700'>Select UN SDGs</h2>
      <div
        className={`grid ${
          collapsed ? 'grid-cols-6' : 'grid-cols-6'
        } gap-6 m-6`}
      >
        {SDGData.map((item) => (
          <SDGCard
            key={item.id}
            data={item}
            color={item.color}
            isSelected={selected.includes(item.id)}
            onToggleSelect={toggleSelect}
          />
        ))}
      </div>
    </div>
  );
};

export default SDGCards;
