import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useProSidebar } from 'react-pro-sidebar';
import { setRatings } from 'state/preferenceSlice';
import { ratingsArray } from 'components/data/ratings';
import LogoCard from 'components/LogoCard.jsx';

const Ratings = () => {
  const ratings = useSelector((state) => state.preference.ratings);
  const [selected, setSelected] = useState(ratings);
  const [ratingsData, setRatingsData] = useState(ratingsArray);
  const dispatch = useDispatch();
  const { collapsed } = useProSidebar();

  const toggleSelect = (id) => {
    const updatedSelected = selected.includes(id)
      ? selected.filter((item) => item !== id)
      : [...selected, id];

    setSelected(updatedSelected);
    dispatch(setRatings(updatedSelected));
  };

  console.log({ ratings });
  return (
    <div className='px-6 mt-6'>
      <h2 className='text-2xl font-bold mb-8 text-gray-700'>Select Ratings</h2>
      <div
        className={`grid ${
          collapsed ? 'grid-cols-6' : 'grid-cols-6'
        } gap-6 m-6`}
      >
        {ratingsData.map((data) => {
          console.log(data);
          return (
            <LogoCard
              data={data}
              isSelected={selected.includes(data.id)}
              onToggleSelect={toggleSelect} // Updated prop name
            />
          );
        })}
      </div>
    </div>
  );
};

export default Ratings;
