import React, { useState, useRef } from "react";
import EmployeeTurnover from "../EmployeeTurnover";
import {
  KeyboardArrowDownOutlined,
  KeyboardArrowUpOutlined,
  KeyboardArrowLeftOutlined,
  KeyboardArrowRightOutlined,
  ArrowDropDownOutlined,
  Download,
  Edit,
  Save,
  Delete,
} from "@mui/icons-material";
import AddIcon from "@mui/icons-material/Add";
import IconButton from "@mui/material/IconButton";
import Arrowicon from "../../../assets/images/arrow-right-s-line.svg";
import { useProSidebar } from "react-pro-sidebar";

const NewhiresTurnover = () => {
  const [rows, setRows] = useState([{ value: "" }]);
  const [alphabetLabels, setAlphabetLabels] = useState(["A"]);
  const [selectedPeriod, setSelectedPeriod] = useState("Jan-March");
  const tabsContainerRef = useRef(null);
  const [isScrollable, setIsScrollable] = useState(false);
  const [editable, setEditable] = useState(false);
  const [isEditMode, setIsEditMode] = useState(false);
  const [hasUnsavedChanges, setHasUnsavedChanges] = useState(false);
  const { collapseSidebar, collapsed } = useProSidebar();
  const [textboxes, setTextboxes] = useState([
    { id: 1, value: "", active: false },
  ]);
  const [nextId, setNextId] = useState(2);
  // Temporary saving/erasing input fields until backend connected
  const [inputValue, setInputValue] = useState(""); // Store input value
  const [tabs, setTabs] = useState([
    {
      id: 1,
      // label: "Enter a Text", // You can change the label as desired
      rows: [{ value: "" }],
      alphabetLabels: ["A"],
      selectedPeriod: "Jan-March",
      editable: true, // Make the default tab editable
      tableData: [],
    },
  ]);

  const inputStyle = {
    borderBottom: editable ? "1px solid #888 " : "none", // Add a bottom border when in edit mode
    textAlign: "right",
    paddingLeft: "2px", // Add left padding
    paddingRight: "2px", // Add right padding
    outline: "none",
  };

  const handleScrollRightClick = () => {
    if (tabsContainerRef.current) {
      tabsContainerRef.current.scrollLeft += 100; // You can adjust the scroll amount as needed
    }
  };

  const handleScrollClick = () => {
    setIsScrollable(!isScrollable);
  };

  const [activeTab, setActiveTab] = useState(1);

  const handleTabChange = (tabId) => {
    setActiveTab(tabId);
  };

  const handleAddTab = () => {
    const newTabId = tabs.length + 1;

    setTabs((prevTabs) => {
      const updatedTabs = prevTabs.map((tab, index) => {
        if (index === tabs.length - 1) {
          return { ...tab, editable: true }; // Add a blue border to the previously added text box
        }
        return tab;
      });

      return [
        ...updatedTabs,
        {
          id: newTabId,
          rows: [{ value: "" }],
          alphabetLabels: ["A"],
          selectedPeriod: "Jan-March",
          editable: true,
          tableData: [],
        },
      ];
    });

    if (tabsContainerRef.current) {
      setIsScrollable(
        tabsContainerRef.current.scrollWidth >
          tabsContainerRef.current.clientWidth
      );
    }

    setActiveTab(newTabId);
  };

  const handlePeriodChange = (period) => {
    setSelectedPeriod(period);
  };

  // const handleEditClick = () => {
  //   setEditable(true);
  // };

  // const handleSaveClick = () => {
  //   setEditable(false);
  //   // Add logic here to save the changes, e.g., update your data.
  // };

  const handleAddRow = () => {
    const updatedTabs = [...tabs];
    const activeTabIndex = tabs.findIndex((tab) => tab.id === activeTab);
    if (activeTabIndex !== -1) {
      updatedTabs[activeTabIndex].rows.push({ value: "" });
      const lastLabel =
        updatedTabs[activeTabIndex].alphabetLabels[
          updatedTabs[activeTabIndex].alphabetLabels.length - 1
        ];
      const nextLabel = String.fromCharCode(lastLabel.charCodeAt(0) + 1);
      updatedTabs[activeTabIndex].alphabetLabels.push(nextLabel);
      setTabs(updatedTabs);
    }
  };

  const handleEdit = () => {
    setIsEditMode(true);
  };

  const handleTabTitleChange = (event, tabId) => {
    const updatedTabs = tabs.map((tab) => {
      if (tab.id === tabId) {
        return { ...tab, label: event.target.value };
      }
      return tab;
    });

    setTabs(updatedTabs);
  };

  const handleEditClick = () => {
    if (editable) {
      // If currently in editing mode, save the input value and exit editing mode
      // You can add actual saving logic here if needed
      // For now, we're just clearing the input value
      setInputValue("");
      setEditable(false);
      setHasUnsavedChanges(false); // Reset to false when entering edit mode
    } else {
      // If not in editing mode, enter editing mode
      setEditable(true);
    }
  };

  const handleSaveClick = () => {
    // Save your data or perform any necessary actions here
    setEditable(false);
    setHasUnsavedChanges(false); // Reset to false when saving
  };

  const handleDeleteTab = () => {
    const updatedTabs = tabs.filter((tab) => tab.id !== activeTab);
    setTabs(updatedTabs);

    // Select the previous tab if there are still tabs left
    if (updatedTabs.length > 0) {
      setActiveTab(updatedTabs[updatedTabs.length - 1].id);
    }
  };

  // const handleInputChange = (index, event) => {
  //   const updatedRows = [...rows]; // Create a copy of the rows array
  //   updatedRows[index].value = event.target.value; // Update the value of the specific row
  //   setRows(updatedRows); // Update the state with the new array
  // };

  const handleInputChange = (index, event) => {
    const newTabs = [...tabs];
    newTabs[activeTab - 1].tableData[index].value = event.target.value;
    setTabs(newTabs);
    setHasUnsavedChanges(true); // Set to true when any input is changed
  };

  // const handleScrollClick = () => {
  //   const tabsContainer = document.querySelector('.tabs-container');
  //   tabsContainer.classList.toggle('scrollable');
  // };

  const renderTableHeaders = () => {
    switch (selectedPeriod) {
      case "Jan-March":
        return (
          <>
            <th></th>
            <th></th>
            <th colSpan="2" className="px-4 py-2 text-start">
              January
            </th>
            <th colSpan="3" className="px-4 py-2 text-center border">
              February
            </th>
            <th colSpan="3" className="px-4 py-2 text-center">
              March
            </th>
          </>
        );
      case "April-June":
        return (
          <>
            <th></th>
            <th></th>
            <th colSpan="2" className="px-4 py-2 text-start">
              April
            </th>
            <th colSpan="3" className="px-4 py-2 text-center border">
              May
            </th>
            <th colSpan="3" className="px-4 py-2 text-center">
              June
            </th>
          </>
        );
      case "July-Sep":
        return (
          <>
            <th></th>
            <th></th>
            <th colSpan="2" className="px-4 py-2 text-start">
              July
            </th>
            <th colSpan="3" className="px-4 py-2 text-center border">
              August
            </th>
            <th colSpan="3" className="px-4 py-2 text-center">
              September
            </th>
          </>
        );
      case "Oct-Dec":
        return (
          <>
            <th></th>
            <th></th>
            <th colSpan="2" className="px-4 py-2 text-start">
              October
            </th>
            <th colSpan="3" className="px-4 py-2 text-center border">
              November
            </th>
            <th colSpan="3" className="px-4 py-2 text-center">
              December
            </th>
          </>
        );
      default:
        return null;
    }
  };

  const renderData = () => {
    // Modify the data based on the selected period
    let data = [];

    switch (selectedPeriod) {
      case "Jan-March":
        data = [
          // Data for Jan-March
        ];
        break;
      case "April-June":
        data = [
          // Data for April-June
        ];
        break;
      case "July-Sep":
        data = [
          // Data for July-Sep
        ];
        break;
      case "Oct-Dec":
        data = [
          // Data for Oct-Dec
        ];
        break;
      default:
        data = [];
    }

    return data.map((row, rowIndex) => (
      <tr key={rowIndex}>
        {row.map((cell, cellIndex) => (
          <td key={cellIndex} className="px-4 py-2 text-sm border">
            {cell}
          </td>
        ))}
      </tr>
    ));
  };

  return (
    <>
      <div className="flex justify-between items-center shadow-md border-gray-100">
        <div
          className={`${
            collapsed ? "w-[90%] " : "w-[75%]"
          } flex justify-between items-center`}
        >
          <div className=" text-left mb-5 ml-6 mt-4">
            <p className="text-sm">Social</p>
            <p className="gradient-text text-[22px] font-bold">
              Employee Hires & Turnover
            </p>
          </div>
          <div className="flex  absolute right-[4.5rem] mt-3">
            <button className="text-[#007EEF] bg-slate-200 rounded-full text-[11px] w-[60px] h-[25px]">
              GRI 302-1
            </button>
            <button className="text-[#fff] bg-amber-400 rounded-full text-[11px] w-[60px] h-[25px] ml-2 ">
              SDG 7
            </button>
            <button className="text-[#fff] bg-red-900 rounded-full text-[11px] w-[60px] h-[25px] ml-2">
              SDG 8
            </button>
            <button className="text-[#fff] bg-yellow-600 rounded-full text-[11px] w-[60px] h-[25px] ml-2 ">
              SDG 12
            </button>
            <button className="text-[#fff] bg-lime-900 rounded-full text-[11px] w-[60px] h-[25px] ml-2">
              SDG 13
            </button>
          </div>
        </div>
      </div>
      <div className="w-full mt-4">
      <h1 className=" pb-8 text-lg ms-3">New Employee Turnover</h1>
        <div className="flex items-center mb-6 ">
            <div className="flex mr-8">
              <div className="relative">
                {/* <label for="location">Select Location:</label> */}
                <select
                  id="location"
                  name="Select Location"
                  className="w-[163px] border appearance-none text-xs text-neutral-500 m-0.5 rounded-md py-1 pl-5 leading-tight focus:outline-none focus:bg-white focus:border-gray-500 cursor-pointer"
                >
                  {/* <!-- Your location options go here --> */}
                  <option value="location1">Select Location</option>
                  <option value="location1">Location 1</option>
                  <option value="location2">Location 2</option>
                  <option value="location3">Location 3</option>
                </select>
                <div className="absolute inset-y-0 right-2 flex items-center pl-3 pointer-events-none">
              <KeyboardArrowDownOutlined
                className="text-neutral-500"
                style={{ fontSize: "16px" }}
              />
            </div>
              </div>

              <div className="relative ml-1">
                {/* <label for="year">Select Year:</label> */}
                <select
                  id="year"
                  name="Select Year"
                  className="w-[163px] border appearance-none text-xs text-neutral-500 m-0.5 rounded-md py-1 pl-5 leading-tight focus:outline-none focus:bg-white focus:border-gray-500 cursor-pointer"
                >
                  {/* <!-- Your year options go here --> */}
                  <option value="location1">Select Year</option>
                  <option value="2022">2022</option>
                  <option value="2023">2023</option>
                  <option value="2024">2024</option>
                </select>
                <div className="absolute inset-y-0 right-2 flex items-center pl-3 pointer-events-none">
              <KeyboardArrowDownOutlined
                className="text-neutral-500"
                style={{ fontSize: "16px" }}
              />
            </div>
              </div>
            </div>
            <button
              className={`${
                selectedPeriod === "Jan-March" ? "bg-blue-100" : "bg-white"
              } mr-2 px-2 text-xs py-1 border rounded `}
              onClick={() => handlePeriodChange("Jan-March")}
            >
              Jan-Mar
            </button>
            <button
              className={`${
                selectedPeriod === "April-June" ? "bg-blue-100" : "bg-white"
              } mr-2 px-2 text-xs py-1 border rounded`}
              onClick={() => handlePeriodChange("April-June")}
            >
              Apr-Jun
            </button>
            <button
              className={`${
                selectedPeriod === "July-Sep" ? "bg-blue-100" : "bg-white"
              } mr-2 px-2 text-xs py-1 border rounded`}
              onClick={() => handlePeriodChange("July-Sep")}
            >
              Jul-Sep
            </button>
            <button
              className={`${
                selectedPeriod === "Oct-Dec" ? "bg-blue-100" : "bg-white"
              } mr-2 px-2 text-xs py-1 border rounded`}
              onClick={() => handlePeriodChange("Oct-Dec")}
            >
              Oct-Dec
            </button>
          </div>
      </div>

      <div
        className={`${
          collapsed ? "w-[90%] " : "w-[75%]"
        } shadow-lg rounded-[10px]`}
      >
        <div className="flex  border border-inherit rounded-t-lg">
          <div
            className={`flex my-2 me-4  tabs-container ${
              isScrollable ? "scrollable" : ""
            }`}
            style={{ overflowX: "auto" }}
            ref={tabsContainerRef}
          >
            {tabs.map((tab) => (
              <button
                key={tab.id}
                className={`tab border-b-2 border-[#2196F3] bg-transparent outline-none focus:border-none focus:ring-0 mx-2 ${
                  tab.id === activeTab ? "active" : ""
                }`}
                onClick={() => handleTabChange(tab.id)}
              >
                {tab.editable ? (
                  <input
                    type="text"
                    placeholder="Enter a Text" // Add a placeholder
                    className={`mr-1 -mb-px p-2 ${
                      activeTab === tab.id
                        ? "text-blue-500 text-[13px] border-b-2 border-blue-500 text-center"
                        : "text-neutral-500 text-[13px]"
                    }`}
                    value={tab.label}
                    onChange={(event) => handleTabTitleChange(event, tab.id)}
                  />
                ) : (
                  tab.label // Display the label as plain text when not editable
                )}
              </button>
            ))}
          </div>

          <div className="flex mx-2">
            {/* <Link to="GHGtemplate">{cell.render("Cell")}</Link>{" "} */}
            <button
              onClick={handleScrollRightClick}
              className={
                isScrollable ? "show-scroll-button " : "hide-scroll-button "
              }
            >
              <img
                src={Arrowicon}
                alt="Scroll Right"
                className="mx-2 border-r-2 border-inherit-500"
              />
            </button>

            <IconButton color="primary" onClick={handleAddTab}>
              <AddIcon />
            </IconButton>
            {/* Edit Icon */}

            <button onClick={handleEditClick}>
              {!editable ? <Edit /> : <Save />}
            </button>
            {/* {editable ? ( // Conditionally render input field when in editing mode
                  <input
                    type="text"
                    placeholder="Enter something"
                    value={inputValue}
                    onChange={handleInputChange}
                  />
                ) : null} */}

            {/* <div className="tabs-container"> */}
            {tabs.map((tab) => (
              <div
                key={tab.id}
                className={`tab ${tab.id === activeTab ? "active" : ""}`}
                onClick={() => setActiveTab(tab.id)}
              >
                {tab.title}
              </div>
            ))}
            <button
              color="primary"
              onClick={handleDeleteTab}
              className="delete-button"
            >
              <Delete />
            </button>
            {/* </div> */}

            {/* <button>
                  <AddIcon />
                </button> */}
          </div>
          {/* <div className="div"></div> */}
        </div>
        {tabs.map((tab) => (
          <div
            key={tab.id}
            className={`${
              activeTab === tab.id ? "block" : "hidden"
            } mb-5 text-xs text-start`}
          >
            <table className="">
              {/* <div className="div">{renderHeadings()}</div> */}
              <thead className="border">
                <tr className="w-full border-t border-b gradient-background rounded-[10px]">
                  {renderTableHeaders()}
                </tr>
                <tr className="border-t border-b gradient-background">
                  {/* <td className="px-4 py-2  border">Employee Category</td> */}
                  <td className="px-2 py-2  border w-[8%]">Age Group</td>{" "}
                  {/* New column */}
                  <td className="px-2 py-2  border w-[10%]">
                    {"< 30 years old"}
                  </td>
                  <td className="px-2 py-2  border w-[11%]">
                    {"30 - 50 years old"}
                  </td>
                  <td className="px-2 py-2  border w-[10%]">
                    {"> 50 years old"}
                  </td>
                  <td className="px-2 py-2  border w-[10%]">
                    {"< 30 years old"}
                  </td>
                  <td className="px-2 py-2  border w-[11%]">
                    {"30 - 50 years old"}
                  </td>
                  <td className="px-2 py-2  border w-[10%]">
                    {"> 50 years old"}
                  </td>
                  <td className="px-2 py-2  border w-[10%]">
                    {"< 30 years old"}
                  </td>
                  <td className="px-2 py-2  border w-[11%]">
                    {"30 - 50 years old"}
                  </td>
                  <td className="px-2 py-2  border w-[15%]">
                    {"> 50 years old"}
                  </td>
                </tr>
              </thead>
              <tbody className="border-l border-r">
                {rows.map((row, index) => (
                  <React.Fragment key={index}>
                    <tr className="border-b">
                      {/* <td className="px-4 py-2 "></td> */}
                      <td className="px-2 py-2 border-r  border-s ">
                        Male
                      </td>{" "}
                      {/* New column */}
                      <td className={`py-2 ${editable ? "editable" : ""}`}>
                        <input
                          type="text"
                          placeholder="10"
                          autocomplete="off"
                          style={editable ? inputStyle : {}}
                          className="py-1 text-end w-[90%] m-[3px] outline-none"
                          // Other input props
                        />
                      </td>
                      <td className={`py-2 ${editable ? "editable" : ""}`}>
                        <input
                          type="text"
                          placeholder="10"
                          // id="natureofOwnership"
                          name="natureofOwnership"
                          style={editable ? inputStyle : {}}
                          autocomplete="off"
                          className="py-1 text-end w-[90%] m-[3px] outline-none"
                          // value={formData.natureofOwnership}
                          // onChange={handleInputChange}
                        />
                      </td>
                      <td
                        className={`py-2 border-r ${
                          editable ? "editable" : ""
                        }`}
                      >
                        <input
                          type="text"
                          placeholder="10"
                          // id="natureofOwnership"
                          name="natureofOwnership"
                          style={editable ? inputStyle : {}}
                          autocomplete="off"
                          className="py-1 text-end w-[90%] m-[3px] outline-none"
                          // value={formData.natureofOwnership}
                          // onChange={handleInputChange}
                        />
                      </td>
                      <td className={`py-2 ${editable ? "editable" : ""}`}>
                        <input
                          type="text"
                          placeholder="10"
                          // id="natureofOwnership"
                          name="natureofOwnership"
                          style={editable ? inputStyle : {}}
                          autocomplete="off"
                          className="py-1 text-end w-[90%] m-[3px] outline-none"
                          // value={formData.natureofOwnership}
                          // onChange={handleInputChange}
                        />
                      </td>
                      <td className={`py-2 ${editable ? "editable" : ""}`}>
                        <input
                          type="text"
                          placeholder="10"
                          // id="natureofOwnership"
                          name="natureofOwnership"
                          autocomplete="off"
                          style={editable ? inputStyle : {}}
                          className="py-1 text-end w-[90%] m-[3px] outline-none"
                          // value={formData.natureofOwnership}
                          // onChange={handleInputChange}
                        />
                      </td>
                      <td
                        className={`py-2 border-r ${
                          editable ? "editable" : ""
                        }`}
                      >
                        <input
                          type="text"
                          placeholder="10"
                          // id="natureofOwnership"
                          autocomplete="off"
                          style={editable ? inputStyle : {}}
                          name="natureofOwnership"
                          className="py-1 text-end w-[90%] m-[3px] outline-none"
                          // value={formData.natureofOwnership}
                          // onChange={handleInputChange}
                        />
                      </td>
                      <td className={`py-2 ${editable ? "editable" : ""}`}>
                        <input
                          type="text"
                          placeholder="10"
                          // id="natureofOwnership"
                          name="natureofOwnership"
                          autocomplete="off"
                          style={editable ? inputStyle : {}}
                          className="py-1 text-end w-[90%] m-[3px] outline-none"
                          // value={formData.natureofOwnership}
                          // onChange={handleInputChange}
                        />
                      </td>
                      <td className={`py-2 ${editable ? "editable" : ""}`}>
                        <input
                          type="text"
                          placeholder="10"
                          // id="natureofOwnership"
                          name="natureofOwnership"
                          autocomplete="off"
                          style={editable ? inputStyle : {}}
                          className="py-1 text-end w-[90%] m-[3px] outline-none"
                          // value={formData.natureofOwnership}
                          // onChange={handleInputChange}
                        />
                      </td>
                      <td className={`py-2 ${editable ? "editable" : ""}`}>
                        <input
                          type="text"
                          placeholder="10"
                          // id="natureofOwnership"
                          autocomplete="off"
                          name="natureofOwnership"
                          style={editable ? inputStyle : {}}
                          className="py-1 text-end  w-[90%] m-[3px] outline-none"
                          // value={formData.natureofOwnership}
                          // onChange={handleInputChange}
                        />
                      </td>
                    </tr>
                    <tr className="border-b">
                      {/* <td className="px-4 py-2   text-center">
                      {" "}
                      {alphabetLabels[index]}
                    </td> */}
                      <td className="px-2 first-letter:py-2 border-r border-s">
                        Female
                      </td>{" "}
                      {/* New column */}
                      <td className={`py-2 ${editable ? "editable" : ""}`}>
                        <input
                          type="text"
                          placeholder="10"
                          // id="natureofOwnership"
                          name="natureofOwnership"
                          style={editable ? inputStyle : {}}
                          autocomplete="off"
                          className="py-1 text-end  text-end w-[90%] m-[3px]"
                          // value={formData.natureofOwnership}
                          // onChange={handleInputChange}
                        />
                      </td>
                      <td className={`py-2 ${editable ? "editable" : ""}`}>
                        <input
                          type="text"
                          placeholder="10"
                          // id="natureofOwnership"
                          name="natureofOwnership"
                          autocomplete="off"
                          style={editable ? inputStyle : {}}
                          className="py-1 text-end w-[90%] m-[3px]"
                          // value={formData.natureofOwnership}
                          // onChange={handleInputChange}
                        />
                      </td>
                      <td
                        className={`py-2 border-r ${
                          editable ? "editable" : ""
                        }`}
                      >
                        <input
                          type="text"
                          placeholder="10"
                          // id="natureofOwnership"
                          name="natureofOwnership"
                          autocomplete="off"
                          style={editable ? inputStyle : {}}
                          className="py-1 text-end w-[90%] m-[3px] "
                          // value={formData.natureofOwnership}
                          // onChange={handleInputChange}
                        />
                      </td>
                      <td className={`py-2 ${editable ? "editable" : ""}`}>
                        <input
                          type="text"
                          placeholder="10"
                          // id="natureofOwnership"
                          name="natureofOwnership"
                          autocomplete="off"
                          style={editable ? inputStyle : {}}
                          className="py-1 text-end w-[90%] m-[3px]"
                          // value={formData.natureofOwnership}
                          // onChange={handleInputChange}
                        />
                      </td>
                      <td className={`py-2 ${editable ? "editable" : ""}`}>
                        <input
                          type="text"
                          placeholder="10"
                          // id="natureofOwnership"
                          name="natureofOwnership"
                          autocomplete="off"
                          style={editable ? inputStyle : {}}
                          className="py-1 text-end w-[90%] m-[3px]"
                          // value={formData.natureofOwnership}
                          // onChange={handleInputChange}
                        />
                      </td>
                      <td
                        className={`py-2 border-r ${
                          editable ? "editable" : ""
                        }`}
                      >
                        <input
                          type="text"
                          placeholder="10"
                          // id="natureofOwnership"
                          name="natureofOwnership"
                          autocomplete="off"
                          style={editable ? inputStyle : {}}
                          className="py-1 text-end w-[90%] m-[3px]"
                          // value={formData.natureofOwnership}
                          // onChange={handleInputChange}
                        />
                      </td>
                      <td className={`py-2 ${editable ? "editable" : ""}`}>
                        <input
                          type="text"
                          placeholder="10"
                          // id="natureofOwnership"
                          name="natureofOwnership"
                          autocomplete="off"
                          style={editable ? inputStyle : {}}
                          className="py-1 text-end w-[90%] m-[3px]"
                          // value={formData.natureofOwnership}
                          // onChange={handleInputChange}
                        />
                      </td>
                      <td className={`py-2 ${editable ? "editable" : ""}`}>
                        <input
                          type="text"
                          placeholder="10"
                          // id="natureofOwnership"
                          name="natureofOwnership"
                          autocomplete="off"
                          style={editable ? inputStyle : {}}
                          className="py-1 text-end w-[90%] m-[3px]"
                          // value={formData.natureofOwnership}
                          // onChange={handleInputChange}
                        />
                      </td>
                      <td className={`py-2 ${editable ? "editable" : ""}`}>
                        <input
                          type="text"
                          placeholder="10"
                          // id="natureofOwnership"
                          name="natureofOwnership"
                          autocomplete="off"
                          style={editable ? inputStyle : {}}
                          className="py-1 text-end  w-[90%] m-[3px]"
                          // value={formData.natureofOwnership}
                          // onChange={handleInputChange}
                        />
                      </td>
                    </tr>
                    <tr className="border-b">
                      {/* <td className="px-4 py-2  border-b"></td> */}
                      <td className="px-2 py-2 border-r  border-s">
                        Others
                      </td>{" "}
                      {/* New column */}
                      <td className={`py-2 ${editable ? "editable" : ""}`}>
                        <input
                          type="text"
                          placeholder="10"
                          // id="natureofOwnership"
                          name="natureofOwnership"
                          autocomplete="off"
                          style={editable ? inputStyle : {}}
                          className="py-1 text-end w-[90%] m-[3px] outline-none"
                          // value={formData.natureofOwnership}
                          // onChange={handleInputChange}
                        />
                      </td>
                      <td className={`py-2 ${editable ? "editable" : ""}`}>
                        <input
                          type="text"
                          placeholder="10"
                          // id="natureofOwnership"
                          autocomplete="off"
                          name="natureofOwnership"
                          style={editable ? inputStyle : {}}
                          className="py-1 text-end  w-[90%] m-[3px] outline-none"
                          // value={formData.natureofOwnership}
                          // onChange={handleInputChange}
                        />
                      </td>
                      <td
                        className={`py-2 border-r${editable ? "editable" : ""}`}
                      >
                        <input
                          type="text"
                          placeholder="10"
                          // id="natureofOwnership"
                          autocomplete="off"
                          name="natureofOwnership"
                          style={editable ? inputStyle : {}}
                          className="py-1 text-end w-[90%] m-[3px] outline-none"
                          // value={formData.natureofOwnership}
                          // onChange={handleInputChange}
                        />
                      </td>
                      <td className={`py-2 ${editable ? "editable" : ""}`}>
                        <input
                          type="text"
                          placeholder="10"
                          // id="natureofOwnership"
                          name="natureofOwnership"
                          autocomplete="off"
                          style={editable ? inputStyle : {}}
                          className="py-1 text-end w-[90%] m-[3px] outline-none"
                          // value={formData.natureofOwnership}
                          // onChange={handleInputChange}
                        />
                      </td>
                      <td className={`py-2 ${editable ? "editable" : ""}`}>
                        <input
                          type="text"
                          placeholder="10"
                          // id="natureofOwnership"
                          name="natureofOwnership"
                          autocomplete="off"
                          style={editable ? inputStyle : {}}
                          className="py-1 text-end w-[90%] m-[3px] outline-none"
                          // value={formData.natureofOwnership}
                          // onChange={handleInputChange}
                        />
                      </td>
                      <td
                        className={`py-2 border-r ${
                          editable ? "editable" : ""
                        }`}
                      >
                        <input
                          type="text"
                          placeholder="10"
                          // id="natureofOwnership"
                          name="natureofOwnership"
                          autocomplete="off"
                          style={editable ? inputStyle : {}}
                          className="py-1 text-end w-[90%] m-[3px] outline-none"
                          // value={formData.natureofOwnership}
                          // onChange={handleInputChange}
                        />
                      </td>
                      <td className={`py-2 ${editable ? "editable" : ""}`}>
                        <input
                          type="text"
                          placeholder="10"
                          // id="natureofOwnership"
                          name="natureofOwnership"
                          autocomplete="off"
                          style={editable ? inputStyle : {}}
                          className="py-1 text-end w-[90%] m-[3px] outline-none"
                          // value={formData.natureofOwnership}
                          // onChange={handleInputChange}
                        />
                      </td>
                      <td className={`py-2 ${editable ? "editable" : ""}`}>
                        <input
                          type="text"
                          placeholder="10"
                          // id="natureofOwnership"
                          name="natureofOwnership"
                          autocomplete="off"
                          style={editable ? inputStyle : {}}
                          className="py-1 text-end w-[90%] m-[3px] outline-none"
                          // value={formData.natureofOwnership}
                          // onChange={handleInputChange}
                        />
                      </td>
                      <td className={`py-2 ${editable ? "editable" : ""}`}>
                        <input
                          type="text"
                          placeholder="10"
                          // id="natureofOwnership"
                          autocomplete="off"
                          style={editable ? inputStyle : {}}
                          name="natureofOwnership"
                          className="py-1 text-end w-[90%] m-[3px] outline-none"
                          // value={formData.natureofOwnership}
                          // onChange={handleInputChange}
                        />
                      </td>
                    </tr>
                  </React.Fragment>
                ))}
              </tbody>
            </table>
          </div>
        ))}
      </div>

      <EmployeeTurnover />
    </>
  );
};

export default NewhiresTurnover;
