import goal1 from 'assets/SDGIcons/E-WEB-Goal-01.png';
import goal2 from 'assets/SDGIcons/E-WEB-Goal-02.png';
import goal3 from 'assets/SDGIcons/E-WEB-Goal-03.png';
import goal4 from 'assets/SDGIcons/E-WEB-Goal-04.png';
import goal5 from 'assets/SDGIcons/E-WEB-Goal-05.png';
import goal6 from 'assets/SDGIcons/E-WEB-Goal-06.png';
import goal7 from 'assets/SDGIcons/E-WEB-Goal-07.png';
import goal8 from 'assets/SDGIcons/E-WEB-Goal-08.png';
import goal9 from 'assets/SDGIcons/E-WEB-Goal-09.png';
import goal10 from 'assets/SDGIcons/E-WEB-Goal-10.png';
import goal11 from 'assets/SDGIcons/E-WEB-Goal-11.png';
import goal12 from 'assets/SDGIcons/E-WEB-Goal-12.png';
import goal13 from 'assets/SDGIcons/E-WEB-Goal-13.png';
import goal14 from 'assets/SDGIcons/E-WEB-Goal-14.png';
import goal15 from 'assets/SDGIcons/E-WEB-Goal-15.png';
import goal16 from 'assets/SDGIcons/E-WEB-Goal-16.png';
import goal17 from 'assets/SDGIcons/E-WEB-Goal-17.png';

export const sdgdata = [
  {
    id: 1,
    imgSource: goal1,
    frontText: 'NO POVERTY',
    backHeading: 'Goal 1',
    backText: 'End poverty',
    target: 7,
    color: '#e5243b',
  },
  {
    id: 2,
    imgSource: goal2,
    frontText: 'ZERO HUNGER',
    backHeading: 'Goal 2',
    backText: 'End Hunger',
    target: 7,
    color: '#dda63a',
  },
  {
    id: 3,
    imgSource: goal3,
    frontText: 'GOOD HEALTH AND WELL-BEING',
    backHeading: 'Goal 3',
    backText: 'Good health',
    target: 7,
    color: '#4C9F38',
  },
  {
    id: 4,
    imgSource: goal4,
    frontText: 'QUALITY EDUCATION',
    backHeading: 'Goal 4',
    backText: 'Quality education',
    target: 7,
    color: '#C5192D',
  },
  {
    id: 5,
    imgSource: goal5,
    frontText: 'GENDER EQUALITY',
    backHeading: 'Goal 5',
    backText: 'Gender equality',
    target: 7,
    color: '#FF3A21',
  },
  {
    id: 6,
    imgSource: goal6,
    frontText: 'CLEAN WATER AND SANITATION',
    backHeading: 'Goal 6',
    backText: 'clean water',
    target: 7,
    color: '#26BDE2',
  },
  {
    id: 7,
    imgSource: goal7,
    frontText: 'AFFORDABLE AND CLEAN ENERGY',
    backHeading: 'Goal 7',
    backText: 'clean energy',
    target: 7,
    color: '#FCC30B',
  },
  {
    id: 8,
    imgSource: goal8,
    frontText: 'DECENT WORK AND ECONOMIC GROWTH',
    backHeading: 'Goal 8',
    backText: 'economic growth',
    target: 7,
    color: '#A21942',
  },
  {
    id: 9,
    imgSource: goal9,
    frontText: 'INDUSTRY, INNOVATION AND INFRASTRUCTURE',
    backHeading: 'Goal 9',
    backText: 'Innovation and infrastructure',
    target: 7,
    color: '#FD6925',
  },
  {
    id: 10,
    imgSource: goal10,
    frontText: 'REDUCED INEQUALITITES',
    backHeading: 'Goal 10',
    backText: 'reduced inequalities',
    target: 7,
    color: '#DD1367',
  },
  {
    id: 11,
    imgSource: goal11,
    frontText: 'SUSTAINABLE CITIES COMMUNITIES',
    backHeading: 'Goal 11',
    backText: 'sustainable cities',
    target: 7,
    color: '#FD9D24',
  },
  {
    id: 12,
    imgSource: goal12,
    frontText: 'RESPONSIBLE CONSUMPTION AND PRODUCTION',
    backHeading: 'Goal 12',
    backText: 'responsible consumption',
    target: 7,
    color: '#BF8B2E',
  },
  {
    id: 13,
    imgSource: goal13,
    frontText: 'CLIMATE ACTION',
    backHeading: 'Goal 13',
    backText: 'climate action',
    target: 7,
    color: '#3F7E44',
  },
  {
    id: 14,
    imgSource: goal14,
    frontText: 'LIFE BELOW WATER',
    backHeading: 'Goal 14',
    backText: 'life in water',
    target: 7,
    color: '#0A97D9',
  },
  {
    id: 15,
    imgSource: goal15,
    frontText: 'LIFE ON LAND',
    backHeading: 'Goal 15',
    backText: 'life on land',
    target: 7,
    color: '#56C02B',
  },
  {
    id: 16,
    imgSource: goal16,
    frontText: 'PEACE, JUSTICE AND STRONG INSTITUTIONS',
    backHeading: 'Goal 16',
    backText: 'strong institutions',
    target: 7,
    color: '#00689D',
  },
  {
    id: 17,
    imgSource: goal17,
    frontText: 'PARTNERSHIP FOR THE GOALS',
    backHeading: 'Goal 17',
    backText: 'partnership for goals',
    target: 7,
    color: '#19486A',
  },
];
