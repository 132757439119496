import React, { useState, useEffect } from 'react';
import { Check } from '@mui/icons-material';
import { useProSidebar } from 'react-pro-sidebar';

const Card = ({ data, color, isSelected, onToggleSelect }) => {
  const handleClick = () => {
    onToggleSelect(data.id);
  };

  const { collapsed } = useProSidebar();

  return (
    <div className={`relative`} onClick={handleClick}>
      {data && (
        <img
          src={data.imgSource}
          alt='cover'
          className={`object-cover ${
            collapsed ? 'h-40 2-40' : 'h-36 w-36'
          } transition-all`}
        />
      )}

      {color ? (
        <div
          className={`absolute top-0 left-0 w-36 h-36 text-[#2AE4FF] opacity-0 transition duration-500 hover:opacity-100 cursor-pointer p-4 
          ${color === '#e5243b' ? 'bg-[#e5243b]' : ''} 
          ${color === '#dda63a' ? 'bg-[#dda63a]' : ''} 
          ${color === '#4C9F38' ? 'bg-[#4C9F38]' : ''}
          ${color === '#C5192D' ? 'bg-[#C5192D]' : ''}
          ${color === '#FF3A21' ? 'bg-[#FF3A21]' : ''}
          ${color === '#26BDE2' ? 'bg-[#26BDE2]' : ''} 
          ${color === '#FCC30B' ? 'bg-[#FCC30B]' : ''} 
          ${color === '#A21942' ? 'bg-[#A21942]' : ''} 
          ${color === '#FD6925' ? 'bg-[#FD6925]' : ''}
          ${color === '#DD1367' ? 'bg-[#DD1367]' : ''} 
          ${color === '#FD9D24' ? 'bg-[#FD9D24]' : ''}
          ${color === '#BF8B2E' ? 'bg-[#BF8B2E]' : ''} 
          ${color === '#3F7E44' ? 'bg-[#3F7E44]' : ''}
          ${color === '#0A97D9' ? 'bg-[#0A97D9]' : ''} 
          ${color === '#56C02B' ? 'bg-[#56C02B]' : ''}
          ${color === '#00689D' ? 'bg-[#00689D]' : ''} 
          ${color === '#19486A' ? 'bg-[#19486A]' : ''}
          `}
        >
          <p className='text-left font-bold text-gray-300 text-xl'>
            {data.backHeading}
          </p>
          <p className='text-left font-normal text-sm text-white border-b-2 pb-4'>
            {data.backText}
          </p>
          <div className='pt-2 text-white'>
            <div className=''>
              <p className='font-bold text-xl'>Target : {data.target}</p>
            </div>
          </div>
        </div>
      ) : (
        ''
      )}
      {data && isSelected ? (
        <div
          className={`absolute ${
            collapsed ? 'right-[36px]' : 'right-6'
          } bottom-2 rounded-full bg-[#56C02B] px-0.5 h-5`}
        >
          <Check
            style={{ color: 'white', fontSize: '16px', paddingBottom: '6px' }}
          />
        </div>
      ) : (
        <div
          className={`absolute ${
            collapsed ? 'right-[36px]' : 'right-6'
          } bottom-2 rounded-full border border-gray-300 bg-gray-200 transition-all px-[1px] h-5`}
        >
          <Check
            style={{
              color: '#aaa',

              fontSize: '16px',
              paddingBottom: '8px',
            }}
          />
        </div>
      )}
    </div>
  );
};

export default Card;
