import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import DataTable from "components/Data-table/Datatable";
import { TaskArray, getData } from "components/data/scopeInfo";

const Index = () => {
  const data = [
    {
      Task: "Acme Corp-Acme Factory 2-Mobile Combustion-unknown",
      Location: "Khandwa",
      MonthYear: "January 2024",
      Assignedto: "test3@bmw.com",
      Assignedon: "27/01/2023",
      DueDate: "27/01/2023",
      Status: "Approved",
    },
    {
      Task: "Acme Corp-Acme Factory 2-Mobile Combustion-unknown",
      Location: "Khandwa",
      MonthYear: "February 2024",
      Assignedto: "test3@bmw.com",
      Assignedon: "27/01/2023",
      DueDate: "27/01/2023",
      Status: "Under review",
    },
    {
      Task: "Acme Corp-Acme Factory 2-Mobile Combustion-unknown",
      Location: "Khandwa",
      MonthYear: "March 2024",
      Assignedto: "test3@bmw.com",
      Assignedon: "20/01/2023",
      DueDate: "12/01/2023",
      Status: "InProgress",
    },
    {
      Task: "Ccme Corp-Acme Factory 2-Mobile Combustion-unknown",
      Location: "Khandwa",
      MonthYear: "April 2024",
      Assignedto: "best3@bmw.com",
      Assignedon: "28/01/2023",
      DueDate: "29/01/2023",
      Status: "InProgress",
    },
    {
      Task: "Bcme Corp-Acme Factory 2-Mobile Combustion-unknown",
      Location: "Khandwa",
      MonthYear: "May 2024",
      Assignedto: "sest3@bmw.com",
      Assignedon: "28/01/2023",
      DueDate: "29/01/2023",
      Status: "Under review",
    },
    {
      Task: "Acme Corp-Acme Factory 2-Mobile Combustion-unknown",
      Location: "Khandwa",
      MonthYear: "June 2024",
      Assignedto: "dest3@bmw.com",
      Assignedon: "27/01/2023",
      DueDate: "27/01/2023",
      Status: "Approved",
    },
  ];

  return (
    <div>
      <DataTable data={data} defaultItemsPerPage={10} />
    </div>
  );
};

export default Index;
