import React, { useState, useEffect } from 'react';
import { Check } from '@mui/icons-material';
import { useProSidebar } from 'react-pro-sidebar';

const Card = ({ data, isSelected, onToggleSelect }) => {
  const handleClick = () => {
    onToggleSelect(data.id);
  };

  const { collapsed } = useProSidebar();
  console.log({ data, isSelected, onToggleSelect });
  return (
    <div className={`relative border p-2 cursor-pointer`} onClick={handleClick}>
      {data && (
        <div className='flex items-center justify-center h-full'>
          <img
            src={data.imgSource}
            alt='cover'
            className={`object-cover ${
              collapsed ? 'h-40 w-40' : `w-36`
            } transition-all`}
          />
        </div>
      )}

      {data && isSelected ? (
        <div
          className={`absolute ${
            collapsed ? 'right-[36px]' : 'right-2'
          } bottom-2 rounded-full bg-[#56C02B] px-0.5 h-5`}
        >
          <Check
            style={{ color: 'white', fontSize: '16px', paddingBottom: '6px' }}
          />
        </div>
      ) : (
        <div
          className={`absolute ${
            collapsed ? 'right-[36px]' : 'right-2'
          } bottom-2 rounded-full border border-gray-300 bg-gray-200 transition-all px-[1px] h-5`}
        >
          <Check
            style={{
              color: '#aaa',
              fontSize: '16px',
              paddingBottom: '8px',
            }}
          />
        </div>
      )}
      <div>
        <p className='text-center mt-4'>{data.label}</p>
      </div>
      
    </div>
  );
};

export default Card;
