import sbti from 'assets/targets/SBTi.png';

export const targetsArray = [
  {
    id: 1,
    imgSource: sbti,
    label: 'SBTi',
    size: 36,
  },
  {
    id: 2,
    imgSource: sbti,
    label: 'SBTi Net Zero',
    size: 56,
  },
];
