import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useProSidebar } from 'react-pro-sidebar';
import LogoCard from 'components/LogoCard';
import { setCertifications } from 'state/preferenceSlice';
import { certificationsArray } from 'components/data/certifications';

const Certifications = () => {
  const certifications = useSelector(
    (state) => state.preference.certifications
  );
  const [selected, setSelected] = useState(certifications);
  const [CertificationsData, setCertificationsData] =
    useState(certificationsArray);
  const dispatch = useDispatch();
  const { collapsed } = useProSidebar();

  const toggleSelect = (id) => {
    const updatedSelected = selected.includes(id)
      ? selected.filter((item) => item !== id)
      : [...selected, id];

    setSelected(updatedSelected);
    dispatch(setCertifications(updatedSelected));
  };

  console.log({ certifications });
  return (
    <div className='px-6 mt-6'>
      <h2 className='text-2xl font-bold mb-8 text-gray-700'>
        Select Certifications
      </h2>
      <div
        className={`grid ${
          collapsed ? 'grid-cols-6' : 'grid-cols-6'
        } gap-6 m-6`}
      >
        {CertificationsData.map((data) => {
          console.log(data);
          return (
            <LogoCard
              data={data}
              isSelected={selected.includes(data.id)}
              onToggleSelect={toggleSelect} // Updated prop name
            />
          );
        })}
      </div>
    </div>
  );
};

export default Certifications;
