import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
// import Header from './Header';
// import Show from './Show1';
import ExpandableComponent from 'components/ExpandableComponent';
import { scope1Info, scope2Info, scope3Info } from 'components/data/scopeInfo';
import axios from 'axios';
import { setNextQuarter, setRowsState } from 'state/emissionSlice';
import WasteHeaderSection from './WasteHeaderSection';
import EnergyHeaderSection from './EnergyHeaderSection';
import EnergyShowContent from './EnergyShowContent';
import EnergyExpandableSection from './EnergyExpandableSection'
import EnergyExpandableComponent from './EnergyExpandableSection';
import WasteExpandableSection from './WasteExpandableSection';
import WasteShowContent from './WasteShowContent';
import Energyconsumption from 'pages/Energy/energy-consumption/energy-consumption';

const Energy = () => {
  const location = useSelector((state) => state.emission?.location);
  const year = useSelector((state) => state.emission?.year);
  const quarter = useSelector((state) => state.emission?.quarter);
  const rowsData = useSelector((state) => state.emission?.rowsState);
  const token = useSelector((state) => state.auth?.authToken);
  const loginUser = useSelector((state) => state.auth.user);

  const dispatch = useDispatch();

  const [calculating, setCalculating] = useState(false);
  const [calculated, setCalculated] = useState(false);
  const [monthsCalculated, setMonthsCalculated] = useState([]);

  const handleCalculate = async (event) => {
    console.log('rows Data', rowsData);
    handleSubmitData(event);
    if (!monthsCalculated.includes(quarter)) {
      setCalculating(true);

      setTimeout(() => {
        setCalculating(false);
        setCalculated(true);

        if (!monthsCalculated.includes(quarter)) {
          setMonthsCalculated((prevMonthsCalculated) => [
            ...prevMonthsCalculated,
            quarter,
          ]);
        }
      }, 2000);
    }
  };

  useEffect(() => {
    if (monthsCalculated.includes(quarter)) {
      setCalculated(true);
    } else {
      setCalculated(false);
    }
  }, [quarter]);

  // Sending data for batch emission estimation

  const handleSubmitData = async (event) => {
    console.log('submit data triggered');
    event.preventDefault();

    const url = 'https://sustainext-backend.azurewebsites.net/calculateview';

    const data = Object.keys(rowsData)
      .map((key) => {
        const rowDataArray = rowsData[key];
        const row_data = [];

        rowDataArray.forEach((rowData, rowIndex) => {
          if (rowData.category !== '') {
            row_data.push({
              row_number: rowIndex + 1,
              sector: rowData.category,
              category: rowData.subCategory,
              activity_data: {
                activity_id: rowData.selectedActivity.activity_id,
                emmissionfactorid: rowData.selectedActivity.uuid,
                name: rowData.selectedActivity.name,
              },
              value1: parseInt(rowData.value1),
              value2: rowData.value2 ? parseInt(rowData.value2) : '',
              unit_type: rowData.unitType.toLowerCase(),
              unit1: rowData.unit[0],
              unit2: rowData.unit[1] ? rowData.unit[1] : '',
              file: rowData.file || 'file',
              assign_to: 'agarwal.riti07@gmail.com',
            });
          }
        });

        return {
          no: parseInt(key),
          row_data: row_data,
        };
      })
      .filter((item) => item.row_data.length > 0);

    const payload = {
      username: loginUser,
      organization: 'Acme Group',
      batch_data: {
        location: location || 'Head Office',
        year: year || '2002',
        month: quarter || 'APR',
        data: data,
      },
    };

    const headers = {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + token,
    };

    try {
      const response = await axios.post(url, payload, {
        headers: headers,
      });
      console.log('Data Sent Successfully:', response.data);
      dispatch(
        setRowsState({
          scope: 1,
          rows: [
            {
              category: '',
              subCategory: '',
              activities: [],
              value1: '',
              value2: '',
              unit: [],
              file: null,
              fileName: '',
              modifiedTime: '',
              activity: '',
              selectedActivity: {},
              unitType: '',
              assignTo: '',
              uploadedBy: '',
            },
          ],
        })
      );
      // dispatch(
      //   setRowsState({
      //     scope: 2,
      //     rows: [
      //       {
      //         category: '',
      //         subCategory: '',
      //         activities: [],
      //         value1: '',
      //         value2: '',
      //         unit: [],
      //         file: null,
      //         fileName: '',
      //         modifiedTime: '',
      //         activity: '',
      //         selectedActivity: {},
      //         unitType: '',
      //         assignTo: '',
      //         uploadedBy: '',
      //       },
      //     ],
      //   })
      // );
      // dispatch(
      //   setRowsState({
      //     scope: 3,
      //     rows: [
      //       {
      //         category: '',
      //         subCategory: '',
      //         activities: [],
      //         value1: '',
      //         value2: '',
      //         unit: [],
      //         file: null,
      //         fileName: '',
      //         modifiedTime: '',
      //         activity: '',
      //         selectedActivity: {},
      //         unitType: '',
      //         assignTo: '',
      //         uploadedBy: '',
      //       },
      //     ],
      //   })
      // );
      dispatch(setNextQuarter());
    } catch (error) {
      console.error('Error in sending Emissions Data:', error);
    }
    console.log(payload, 'payload', rowsData[1]);
  };

  return (
    <div className='relative'>

      <EnergyHeaderSection monthsCalculated={monthsCalculated} />
      <Energyconsumption    quarter={quarter}/>

      {/* <EnergyExpandableSection
        label='Purchased heating,cooling,electricity and steam'
        // description='Direct emission from operations'
      >
        <EnergyShowContent
          scope='1'
          scopeInfo={scope1Info}
          location={location}
          year={year}
          quarter={quarter}
        />
      </EnergyExpandableSection>
      <EnergyExpandableSection
        label='Consumed fuel and energy'
        // description='InDirect emission from operations'
      >
        <EnergyShowContent
          scope='2'
          scopeInfo={scope2Info}
          location={location}
          year={year}
          quarter={quarter}
        />
      </EnergyExpandableSection>
      <EnergyExpandableSection
        label='Self generated-not consumed'
        // description='InDirect emission from operations'
      >
        <EnergyShowContent
          scope='2'
          scopeInfo={scope2Info}
          location={location}
          year={year}
          quarter={quarter}
        />
      </EnergyExpandableSection>
      <EnergyExpandableSection
        label='Energy sold'
        // description='InDirect emission from operations'
      >
        <EnergyShowContent
          scope='2'
          scopeInfo={scope2Info}
          location={location}
          year={year}
          quarter={quarter}
        />
      </EnergyExpandableSection> */}
      {/* <ExpandableComponent
        label='Scope 3'
        description='All other emissions (associated)'
      >
        <Show
          scope='3'
          scopeInfo={scope3Info}
          location={location}
          year={year}
          quarter={quarter}
        />
      </ExpandableComponent> */}

   
    </div>
  );
};

export default Energy;
