import React, { useState, useEffect } from 'react';
import logo from '../../assets/logos/sustainext-new-white-logo.png';
import { VisibilityOutlined, VisibilityOff } from '@mui/icons-material';
import { Link, useNavigate, useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { loginUser, setLoading } from 'state/authSlice';
import loginbackgroundimage from '../../assets/images/login-background-image.png';
import { useSelector } from 'react-redux';
import { setLoginUser, setOrgName, setUserid } from 'state';
import { setShowModal } from 'state/authSlice';
import axios from 'axios';
import { setLoggedIn } from 'state/authSlice';
import SustainextHQ from 'pages/Home/useronboarding';

const Signintwo = () => {
  const [showPassword, setShowPassword] = useState(false);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const showModal = useSelector((state) => state.auth.showModal);

  console.log('showModal value:', showModal);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const loading = useSelector((state) => state.auth.loading);

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
    dispatch(setLoginUser(e.target.value));
    localStorage.setItem('email', e.target.value);
  };

  const handleLogin = (e) => {
    console.log('login initiated');
    e.preventDefault();

    // Perform form validation
    if (!email || !password) {
      setError('Please enter your email and password');
      return;
    }

    const credentials = {
      username: email,
      password: password,
    };

    dispatch(loginUser(credentials))
      .unwrap()
      .then(() => {
        dispatch(setShowModal(false));
      
        fetchUserDetails();
      })
      .catch((error) => {
        console.log('Login error:', error);
        setPassword('');
        setError('Invalid email address or password');
      });
  };
  const newmail=email;
   const tempuser = newmail.split('@')[0];
  const fetchUserDetails = async () => {

    await  axios({
      method: 'get',
      url:  `${process.env.REACT_APP_BACKEND_URL}/user_org?username=${email}`,
    
    })
      .then(function (response) {
        console.log(response.data.org_data[0].name, 'user details');
        localStorage.setItem('orgName', response.data.org_data[0].name);
        localStorage.setItem('user_id', response.data.user_detail[0].id);
         localStorage.setItem('name', tempuser);
         localStorage.setItem('auth', response.data.has_login_first);
        dispatch(setOrgName(response.data.org_data[0].name));
        // dispatch(setUserid(response.data.user_detail[0].id));
        navigate('/home/sustainextHQ');
      })
      .catch((error) => {
        console.log('Login error:', error);
        setPassword('');
        setError('Invalid email address or password');
      });
 

  };

  useEffect(() => {
    console.log('showModal value:', showModal);
  }, [showModal]);

  return (
    <>
      {/* {loading ? (
        <div className='flex justify-center items-center text-[2rem] font-semiBold gradient-text h-[100vh]'>
          Please wait while we log you in...
        </div>
      ) : ( */}
      {/* {!showModal ? (
      <SustainextHQ />
      ):( */}
      <div className='h-screen place-items-center bg-[#f8f8ff] flex flex-wrap justify-end relative login-screen '>
        <div className=' absolute inset-0'>
          <div className='absolute mx-2 '>
            <Link to='/'>
              <img
                className='w-[250px] h-[120px] -ms-4 cursor-pointer'
                src={logo}
                alt='Sustainext'
              />
            </Link>
          </div>
          <img
            src={loginbackgroundimage}
            alt='...'
            className='w-full h-full object-cover rounded border-none'
          />
          <p className='absolute bottom-20  2xl:w-[38%] xl:w-[38%] lg:w-[38%] sm:w-full md:w-full left-10 text-white'>
            Simplifying your Sustainability Journey
          </p>
        </div>
        <div className='bg-white p-6  2xl:mx-20 xl:mx-20 lg:mx-20 sm:mx-0 md:mx-0 rounded-lg shadow-xl absolute flex justify-center items-center '>
          <div className='min-h-full h-[500px] w-[350px] 2xl:mx-8 xl:mx-8 lg:mx-8 sm:mx-0 md:mx-0'>
            <div className='sm:w-full sm:max-w-sm '>
              <h2 className='mt-14 text-[22px] font-bold leading-9 tracking-tight text-gray-900'>
                Welcome back
              </h2>
              <p className='text-[15px] text-[#727272]'>
                Login to sustainable solutions{' '}
              </p>
              {error && (
                <div
                  className='bg-red-100 border-l-4 border-red-500 text-red-700 p-4 mt-2'
                  role='alert'
                >
                  <p className='font-bold'>{error}</p>
                </div>
              )}
            </div>

            <div className='mt-5  sm:w-full sm:max-w-sm '>
              <form className='space-y-2' action='#' method='POST'>
                <div>
                  <label
                    htmlFor='email'
                    className='block text-[15px] font-medium leading-6 text-gray-900'
                  >
                    Email
                  </label>
                  <div className='mt-2'>
                    <input
                      id='email'
                      name='email'
                      type='email'
                      placeholder='Enter email address'
                      autoComplete='email'
                      value={email}
                      onChange={handleEmailChange}
                      required
                      className='block w-full rounded-md border-0 py-1.5 custom-input pl-4 text-gray-600 shadow-sm ring-1  ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-1 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6'
                    />
                  </div>
                  {error && !email && (
                    <p className='text-red-500 text-sm mt-1'>
                      Please enter your email
                    </p>
                  )}
                </div>

                <div>
                  <div>
                    <label
                      htmlFor='password'
                      className='block text-[15px] font-medium leading-6 text-gray-900'
                    >
                      Password
                    </label>
                    <div className='relative mt-2 rounded-md shadow-sm'>
                      <input
                        id='password'
                        name='password'
                        placeholder='Enter password'
                        type={showPassword ? 'text' : 'password'}
                        autoComplete='current-password'
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        required
                        className='block w-full rounded-md border-0 py-1.5 custom-input pl-4 text-gray-600 shadow-sm ring-1  ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-1 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6'
                      />
                      <div className='absolute inset-y-0 right-0 mr-2 flex items-center cursor-pointer'>
                        <div onClick={handleClickShowPassword}>
                          {showPassword ? (
                            <VisibilityOutlined
                              style={{ color: '#ADADAD', fontSize: '20px' }}
                            />
                          ) : (
                            <VisibilityOff
                              style={{ color: '#ADADAD', fontSize: '20px' }}
                            />
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                  {error && !password && (
                    <p className='text-red-500 text-sm mt-1'>
                      Please enter your password
                    </p>
                  )}
                </div>
                <div className='flex justfy-between items-center mb-10 '>
                  <label className=' mt-2 flex items-center md:w-2/3 block text-gray-500 font-bold'>
                    <input
                      className='mr-2 leading-tight accent-green-500 text-[#D6D4D4] '
                      type='checkbox'
                    />

                    <span className='text-[12px] text-[#0A0528]'>
                      Remember me
                    </span>
                  </label>
                  <div className=' w-[40%] mt-2 ml-16'>
                    <Link
                      to='/forgotten-password'
                      className='font-semibold  text-end text-[#007eef] text-[12px] hover:text-[#6f3ccf]'
                    >
                      Forgot password?
                    </Link>
                  </div>
                </div>

                <div>
                  <button
                    type='submit'
                    className='flex w-full justify-center rounded-md  bg-gradient-to-r from-[#007EEF] to-[#2AE4FF] hover:bg-gradient-to-r hover:from-[#00aeef] hover:to-[#6adf23] px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm  focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 mt-8'
                    onClick={handleLogin}
                  >
                    {loading ? 'Logging in...' : 'LOGIN'}
                  </button>
                </div>
              </form>
              <p className='py-5 text-center text-[12px] text-[#0A0528]'>
                Don't have an account?{' '}
                <Link
                  to='/register'
                  className='font-semibold text-[12px] leading-6 text-[#007eef] hover:text-[#6f3ccf]'
                >
                  Sign up
                </Link>
              </p>
            </div>
          </div>
        </div>
      </div>
      {/* )} */}
      {/* )} */}
    </>
  );
};
export default Signintwo;
