import djsi from 'assets/ratings/DJSI.svg';
import msci from 'assets/ratings/MSCI.png';
import sustainalytics from 'assets/ratings/Sustainalytics.jpeg';

export const ratingsArray = [
  {
    id: 1,
    imgSource: djsi,
    label: 'DJSI',
    size: 36,
  },
  {
    id: 2,
    imgSource: msci,
    label: 'MSCI',
    size: 56,
  },
  {
    id: 3,
    imgSource: sustainalytics,
    label: 'SUSTAINALYTICS',
    size: 56,
  },
];
