import React, { useState, useEffect } from "react";
import SDGCards from "./SDGCards";
import Pledges from "./Pledges";
import Frameworks from "./Frameworks";
import Certifications from "./Certifications";
import Targets from "./Targets";
import Ratings from "./Ratings";
import { Link } from "react-router-dom";
import { ArrowBack, ArrowForward } from "@mui/icons-material";
import Regulation from "./Regulation";

const Preferences = () => {
  const searchParams = new URLSearchParams(window.location.search);
  const currentPageParam = searchParams.get("currentPage");
  const initialPage = currentPageParam ? parseInt(currentPageParam) : 1;
  const [currentPage, setCurrentPage] = useState(initialPage);

  useEffect(() => {
    setCurrentPage(initialPage);
  }, [initialPage]);

  const handlePrevPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const handleNextPage = () => {
    const maxPage = 5;

    if (currentPage < maxPage) {
      setCurrentPage(currentPage + 1);
    }
  };

  return (
    <div>
      {currentPage === 1 && <SDGCards />}
      {currentPage === 2 && <Frameworks />}
      {currentPage === 3 && <Certifications />}
      {currentPage === 4 && <Targets />}
      {currentPage === 5 && <Ratings />}
      {currentPage === 6 && <Regulation/>}

      <div className="flex justify-end w-full  ">
        {/* <button
          onClick={handlePrevPage}
          disabled={currentPage === 1}
          className='bg-gray-200 px-4 py-2 rounded-md text-gray-700 disabled:opacity-50'
        >
          <ArrowBack />
        </button> */}
        <Link
          to="/home"
          className="w-[10%] text-center rounded-md mr-6 bg-blue-500 bg-opacity-100 shadow-md hover:shadow-lg active:shadow-none "
        >
          <button className=" px-4 py-2 font-bold text-white">Save</button>
        </Link>
        {/* <button
          onClick={handleNextPage}
          disabled={currentPage === 5}
          className='bg-green-500 px-4 py-2 rounded-md text-white disabled:opacity-50'
        >
          <ArrowForward />
        </button> */}
      </div>
    </div>
  );
};

export default Preferences;
