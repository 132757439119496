import InsertLinkOutlinedIcon from '@mui/icons-material/InsertLinkOutlined';
import ChevronRightOutlinedIcon from '@mui/icons-material/ChevronRightOutlined';
import ClearOutlinedIcon from '@mui/icons-material/ClearOutlined';

export const Energydata = [

    {
        
        category: ["1"],
        header:[
            <h5 className="text-sky-600 text-[17px] font-bold">GRI 302-1</h5>
        ],
        data: [
            <>
       
     <div className="p-2 pt-5 pb-4 ">
      <p className="text-[15px] text-[#0057A5] mb-4">Energy consumption within the organization</p>
      <div className="h-[350px] overflow-y-scroll custom-scrollbar">
      <p className="text-[13px] text-[#222222] mb-4">Compilation Requirements:</p>
      <p className="text-[11px] text-[#727272]">When compiling the information specified in Disclosure 302-1, the reporting</p>
      <p className="text-[11px] text-[#727272] mb-2">organization shall:</p>
      <ul className="list-disc ml-6 mb-4">
        <li className="text-[11px] text-[#727272]">Avoid the double-counting of fuel consumption, when reporting self-generated energy consumption. </li>
        <li className="text-[11px] text-[#727272]">If the organization generates electricity from a nonrenewable or renewable fuel source and then consumes the generated electricity, the energy consumption shall be counted once under fuel consumption.</li>
        <li className="text-[11px] text-[#727272]">Report fuel consumption separately for non-renewable and renewable fuel sources.</li>
        <li className="text-[11px] text-[#727272]">Only report energy consumed by entities owned or controlled by the organization.</li>
      </ul>
      <p className="text-[13px] text-[#222222] mb-4">Guidance</p>
      <p className="text-[11px] text-[#727272] mb-2">Background:</p>
      <ul className="list-disc ml-6 mb-4">
        <li className="text-[11px] text-[#727272]">For some organizations, electricity is the only significant form of energy they consume. For others, energy sources such as steam or water provided from a district heating plant or chilled water plant can also be important. </li>
        <li className="text-[11px] text-[#727272]">Energy can be purchased from sources external to the organization or produced by the organization itself (self-generated)</li>
        <li className="text-[11px] text-[#727272]">Non-renewable fuel sources can include fuel for combustion in boilers, furnaces, heaters, turbines, flares, incinerators, generators and vehicles that are owned or controlled by the organization. </li>
        <li className="text-[11px] text-[#727272]">Non-renewable fuel sources cover fuels purchased by the organization. They also include fuel generated by the organization’s activities – such as mined coal, or gas from oil and gas extraction.</li>
        <li className="text-[11px] text-[#727272]">Renewable fuel sources can include biofuels, when purchased for direct use, and biomass in sources owned or controlled by the organization. </li>
        <li className="text-[11px] text-[#727272]">Consuming non-renewable fuels is usually the main contributor to direct (Scope 1) GHG emissions, which are reported in Disclosure 305-1 of GRI 305: Emissions 2016. Consuming purchased electricity, heating, cooling, and steam contributes to the organization’s energy  indirect (Scope 2) GHG emissions, which are reported in Disclosure 305-2 of GRI 305: Emissions 2016</li>
      </ul>
     </div>
  <div>
    <a className="text-[14px] text-[#2196F3] mt-3">Learn more <ChevronRightOutlinedIcon sx={{ml:1}}/></a>
  </div>
     </div>
     </>
        ],


    },
    {
        category: ["2"],
        header:[
            <>
            <div className='flex'>
                <img src="../info/sdg7.webp"/> <h5 className="text-amber-400 text-[17px] font-bold ml-2">SDG 7</h5>
            </div>
           
            </>
        ],
        data: [
            <>
       
     <div className="p-2 pt-5 pb-4 ">
      <div className="h-[350px]">
      <p className="text-[13px] text-[#222222] mb-2">Target 7.2</p>
      <p className="text-[11px] text-[#727272] mb-4">By 2030, increase substantially the share of renewable energy in the global energy mix</p>
      <p className="text-[11px] text-[#222222] mb-2">Indicators</p>
      <p className="text-[11px] text-[#727272] mb-2">7.2.1</p>
      <p className="text-[11px] text-[#727272] mb-4">Renewable energy share in the total final energy consumption</p>
      <p className="text-[13px] text-[#222222] mb-2">Target 7.3</p>
      <p className="text-[11px] text-[#727272] mb-4">By 2030, double the global rate of improvement in energy efficiency</p>
      <p className="text-[11px] text-[#222222] mb-2">Indicators</p>
      <p className="text-[11px] text-[#727272] mb-2">7.3.1</p>
      <p className="text-[11px] text-[#727272] mb-2">Energy intensity measured in terms of primary energy and GDP</p>
     </div>
  <div>
    <a className="text-[14px] text-[#2196F3] mt-3">Learn more <ChevronRightOutlinedIcon sx={{ml:1}}/></a>
  </div>
     </div>
     </>
        ],

 

    },
    {
        category: ["3"],
        header:[
            <>
            <div className='flex'>
                <img src="../info/sdg8.webp"/> <h5 className="text-red-900 text-[17px] font-bold ml-2">SDG 8</h5>
            </div>
           
            </>
        ],
        data: [
            <>
       
     <div className="p-2 pt-5 pb-4 ">
      <div className="h-[350px]">
      <p className="text-[13px] text-[#222222] mb-2">Target 8.4</p>
      <p className="text-[11px] text-[#727272] mb-4">Improve progressively, through 2030, global resource efficiency in consumption and production and endeavour to decouple economic growth from environmental degradation, in accordance with the 10-Year Framework of Programmes on Sustainable Consumption and Production, with developed countries taking the lead</p>
      <p className="text-[11px] text-[#222222] mb-2">Indicators</p>
      <p className="text-[11px] text-[#222222] mb-2">8.4.1</p>
      <p className="text-[11px] text-[#727272] mb-4">Material footprint, material footprint per capita, and material footprint per GDP</p>

      <p className="text-[11px] text-[#222222] mb-2">Indicators</p>
      <p className="text-[11px] text-[#222222] mb-2">8.4.2</p>
      <p className="text-[11px] text-[#727272] mb-2">Domestic material consumption, domestic material consumption per capita, and domestic material consumption per GDP</p>
     </div>
  <div>
    <a className="text-[14px] text-[#2196F3] mt-3">Learn more <ChevronRightOutlinedIcon sx={{ml:1}}/></a>
  </div>
     </div>
     </>
        ],

 

    },
    {
        category: ["4"],
        header:[
            <>
            <div className='flex w-[100px]'>
                <img src="../info/sdg12.webp"/> <h5 className="text-yellow-600 text-[17px] font-bold ml-2">SDG 12</h5>
            </div>
           
            </>
        ],
        data: [
            <>
       
     <div className="p-2 pt-5 pb-4 ">
      <div className="h-[350px]">
      <p className="text-[13px] text-[#222222] mb-2">Target 12.2</p>
      <p className="text-[11px] text-[#727272] mb-4">By 2030, achieve the sustainable management and efficient use of natural resources</p>
      <p className="text-[11px] text-[#222222] mb-2">Indicators</p>
      <p className="text-[11px] text-[#222222] mb-2">12.2.1</p>
      <p className="text-[11px] text-[#727272] mb-4">Material footprint, material footprint per capita, and material footprint per GDP</p>
      <p className="text-[11px] text-[#222222] mb-2">12.2.2</p>
      <p className="text-[11px] text-[#727272] mb-2">Domestic material consumption, domestic material consumption per capita, and domestic material consumption per GDP</p>
     </div>
  <div>
    <a className="text-[14px] text-[#2196F3] mt-3">Learn more <ChevronRightOutlinedIcon sx={{ml:1}}/></a>
  </div>
     </div>
     </>
        ],

 

    },
    {
        category: ["5"],
        header:[
            <>
            <div className='flex w-[100px]'>
                <img src="../info/sdg13.webp"/> <h5 className="text-lime-900  text-[17px] font-bold ml-2">SDG 13</h5>
            </div>
           
            </>
        ],
        data: [
            <>
       
     <div className="p-2 pt-5 pb-4 ">
      <div className="h-[350px]">
      <p className="text-[13px] text-[#222222] mb-2">Target 13.1</p>
      <p className="text-[11px] text-[#727272] mb-4">Strengthen resilience and adaptive capacity to climate-related hazards and natural disasters in all countries</p>
      <p className="text-[11px] text-[#222222] mb-2">Indicators</p>
      <p className="text-[11px] text-[#222222] mb-2">13.1.1</p>
      <p className="text-[11px] text-[#727272] mb-4">Number of deaths, missing persons and directly affected persons attributed to disasters per 100,000 population</p>
      <p className="text-[11px] text-[#222222] mb-2">13.1.2</p>
      <p className="text-[11px] text-[#727272] mb-2">Number of countries that adopt and implement national disaster risk reduction strategies in line with the Sendai Framework for Disaster Risk Reduction 2015–2030</p>
      <p className="text-[11px] text-[#222222] mb-2">13.1.3</p>
      <p className="text-[11px] text-[#727272] mb-2">Proportion of local governments that adopt and implement local disaster risk reduction strategies in line with national disaster risk reduction strategies</p>
     </div>
  <div>
    <a className="text-[14px] text-[#2196F3] mt-3">Learn more <ChevronRightOutlinedIcon sx={{ml:1}}/></a>
  </div>
     </div>
     </>
        ],

 

    },

]