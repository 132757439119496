import reg from "assets/images/regulation.png";
import cand from "assets/images/cand.png"

export const regulationArray = [
  {
    id: 1,
    imgSource: reg,
    label: "SB-253",
    size: 36,
  },
  {
    id: 2,
    imgSource: reg,
    label: "SB-261",
    size: 36,
  },
  {
    id: 3,
    imgSource: cand,
    label: "Bill S-211",
    size: 36,
  },
  {
    id: 4,
    imgSource: cand,
    label: "GHGRP",
    size: 36,
  }
];
